import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { ownerSelector } from "../../store/store";

type PricingCardProps = {
  currencySymbol: string;
  amount: number;
  name: string;
  features: string[];
  discount?: number;
  showDiscount?: boolean;
  planId: string;
  paymentUrl: string;
};

const PlanCard = ({
  currencySymbol,
  amount,
  name,
  features,
  discount,
  showDiscount,
  planId,
  paymentUrl,
}: PricingCardProps) => {
  const { owner } = useSelector(ownerSelector);
  const planhistory = owner?.planhistory[owner?.planhistory.length - 1];
  return (
    <div className="bg-white relative p-4 rounded-md w-[300px] h-[550px] border border-mainColor">
      <div className="flex flex-col gap-4  justify-center">
        <h3 className="text-lg font-semibold">{name}</h3>
        <div className="flex flex-col gap-2">
          <div className=" flex items-center">
            <p className="text-mainColor font-semibold text-2xl flex items-center gap-2">
              {showDiscount && (
                <span className="text-xs line-through text-gray-400">
                  {currencySymbol}
                  {(amount / (1 - discount! / 100)).toFixed(2)}
                </span>
              )}
              {currencySymbol}
              {amount}
            </p>{" "}
            / month
            {showDiscount && (
              <small className="text-xs px-[4px] rounded-lg ml-2 bg-mainColor text-white">
                Save {discount}%
              </small>
            )}
          </div>
          <div className="flex flex-col gap-2">
            {features.map((feature, index) => (
              <p className="text-sm flex items-center gap-2" key={index}>
                <FaCheckCircle size={12} className="text-mainColor" />
                {feature}
              </p>
            ))}
          </div>
        </div>
        <div className=" flex w-full items-center justify-center">
          <button
            onClick={()=>{window.location.href = paymentUrl}}
            disabled={planId === planhistory?.planName}
            className="bg-mainColor text-sm absolute bottom-6 text-white p-2 rounded-md"
          >
            {planId === planhistory?.planName
              ? "Current Plan"
              : "Switch to " + planId}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
