import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { Popover } from "antd";
import { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useWindowResize } from "../../hooks/useWindowResize";
import {
  RootState,
  campaignSelector,
  dashboardSelector,
  ownerSelector,
} from "../../store/store";
import { deleteAllRequests, getRequestsList } from "../../utils";
import LinkedinGraphs from "./LinkedinGraphs";
import Progresscard from "./Progresscard";
import ProspectingStatusCard from "./ProspectingStatusCard";
import StatsCardReusable from "./StatsCardReusable";
import LinkedinStats from "./LinkedinStats";
import axios from "../../utils/axios";
import { setSingleCampaign } from "../../store/slices/campaignSlice";
import { MdDelete } from "react-icons/md";
import PendingRequestsModal from "../Modals/PendingRequestsModal";

type Props = {};

const DashboardMain = (props: Props) => {
  const { owner } = useSelector(ownerSelector);
  const { campaignsData, linkedinData, dashCollapsed } =
    useSelector(dashboardSelector);
  const { singleCampaign, selectedCampaign } = useSelector(campaignSelector);
  
  // const [ownerLinkedinData, setOwnerLinkedinData] = useState();
  //const [ownerConnections, setOwnerConnections] = useState(0);
  //const [allRequests, setAllRequests] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const dispatch: ThunkDispatch<RootState, undefined, Action> = useDispatch();
  const { width } = useWindowResize();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        if (selectedCampaign === "") return;
        const { data } = await axios.post("/campaign/getCampaignData", {
          campaignId: selectedCampaign,
        });
        // console.log(data);
        dispatch(setSingleCampaign(data.campaign));
      } catch (error) {
        toast.error("Error fetching campaign data");
        console.log(error);
      }
    })();
  }, [selectedCampaign]);
  

  // const handleRequestsDelete = async () => {
  //   try {
  //     const res = await deleteAllRequests(owner?._id as string);
  //     console.log(res);
  //     setShowDelete(false);
  //     toast.success("Requests deleted successfully");
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  return (
    <div className="flex w-full gap-4   noScroll">
      <div className=" w-full flex flex-col gap-4">
        <div className="flex w-full gap-4">
          {/* <Progresscard /> */}
          <LinkedinStats />
          <ProspectingStatusCard />
          <div className="bg-white w-1/3 justify-center items-center p-6 shadow-lg rounded-md relative">
          {/* <p className="absolute top-2 right-2 rounded-lg text-sm bg-mainColor/80 p-2 text-white flex items-center justify-center">
            <FaCrown className="inline-block mr-2 " />
            <p>{owner.planhistory[0].planName}</p>
          </p> */}
          <div className="w-full flex justify-center items-center flex-col gap-6">
            <div
              onClick={(e) => {
                window.open(
                  `https://www.linkedin.com/in/${owner?.ownerEntityUrn}/`,
                  "_blank"
                );
              }}
              className="flex flex-col justify-center items-center gap-2 cursor-pointer"
            >
              <img
                className={`rounded-full ${
                  width < 1920 ? " w-20 h-20" : "w-28 h-28"
                }`}
                src={owner?.picture}
                alt="placeholder"
              />
              <p className="font-semibold">{owner?.name}</p>
            </div>
            <div className="flex justify-center items-center w-full gap-4">
              <span
                onClick={(e) => {
                  window.open(
                    `https://www.linkedin.com/mynetwork/invite-connect/connections/`,
                    "_blank"
                  );
                }}
                className="flex flex-col justify-center items-center cursor-pointer"
              >
                <p className=" font-semibold">
                  {linkedinData.numOfConnections || 0}
                </p>
                <p className="text-xs">Connections</p>
              </span>
              <span
                // onClick={(e) => {
                //   window.open(
                //     `https://www.linkedin.com/mynetwork/invitation-manager/sent/`,
                //     "_blank"
                //   );
                // }}
                onClick={() => setShowDelete(true)}
                className="relative flex flex-col justify-center items-center  cursor-pointer"
              >
                <span className="absolute cursor-pointer -right-3 p-1 rounded-full bg-red-200 text-red-400 -top-3">
                  <MdDelete size={15} />
                </span>

                <p className=" font-semibold">
                  {linkedinData.numOfPendingRequests || 0}
                </p>
                <p className="text-xs">Pending</p>
              </span>
              <span
                onClick={(e) => {
                  window.open(
                    `https://www.linkedin.com/analytics/profile-views/`,
                    "_blank"
                  );
                }}
                className="flex flex-col justify-center items-center cursor-pointer "
              >
                <p className=" font-semibold">
                  {linkedinData.numberOfProfileViews || 0}
                </p>
                <p className="text-xs">Profile views</p>
              </span>
            </div>
          </div>
        </div>

          {/* <EmailProgersscard /> */}
        </div>
        <div>
          <LinkedinGraphs />
        </div>
      </div>
      {/* <div className="flex flex-col w-[30%] gap-4">
        
        
      </div> */}
      {showDelete && (
        <PendingRequestsModal onClose={() => setShowDelete(false)} />
      )}
    </div>
  );
};

export default DashboardMain;
