import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Team } from '../../types';


interface TeamsState {
  team: Team | null;
  loading: boolean;
  error: string | null;
}

const initialState: TeamsState = {
  team: null,
  loading: false,
  error: null,
};

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    removeMember: (state, action: PayloadAction<string>) => {
        const ownerId = action.payload;
        if (state.team) {
            state.team.members = state.team.members.filter(member => member.ownerId !== action.payload);
        }
    },
    updateTeam: (state, action: PayloadAction<Team | null>) => {
      state.team = action.payload;
    },
    removeTeam: (state) => {
      state.team = null;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const { removeMember, updateTeam, removeTeam, setLoading, setError } = teamsSlice.actions;

export default teamsSlice.reducer;
