import { BsEnvelope, BsEye } from "react-icons/bs";
import { FaMessage, FaPaperclip } from "react-icons/fa6";
import { MdCheck, MdHourglassBottom, MdMessage } from "react-icons/md";
import { useSelector } from "react-redux";
import { campaignSelector } from "../../store/store";
import { CiClock1 } from "react-icons/ci";

type Props = {};

const Overview = (props: Props) => {
  const { singleCampaign } = useSelector(campaignSelector);

  return (
    <div className=" h-full  max-h-[400px] w-full slimScrollbar overflow-y-auto  gap-2 flex flex-col justify-start items-center bg-white my-2 rounded-xl p-2 text-xs">
      <div className="w-full">
        {singleCampaign?.actions.map((action: any, index: number) => {
          return (
            <div key={index} className="w-full  ">
              {action.type === "invitation" && (
                <div className="w-full flex flex-col my-2">
                  <div className="mb-2 flex justify-center items-center gap-2">
                    <div className="w-1/2 h-[1px] bg-gray-600"></div>
                    <p className="font-semibold uppercase">Invitation</p>
                    <div className="w-1/2 h-[1px] bg-gray-600"></div>
                  </div>
                  <p className="bg-blue-500 flex items-center text-white w-full p-1 px-2 rounded-lg">
                    <span className="text-black bg-white p-1 rounded-md">
                      <BsEnvelope size={15} className="" />
                    </span>
                    <span className="ml-2 text-lg mr-1">
                    {
                      (()=>{
                        let count  = 0;
                        for(let prospect of singleCampaign?.prospectsWiseCampaignActions){
                          if(prospect.campaignActions.length > 0 && prospect.campaignActions[index].done == "DONE"){
                            count++;
                          }
                        }
                        return count;
                      })()
                    }
                    </span>
                    Prospects Invited
                  </p>

                  <div className="w-[2px] h-6 bg-blue-300  mx-auto"></div>
                  <p className="bg-orange-400 flex items-center text-white w-full p-1 px-2 rounded-lg">
                    <span className="text-black bg-white p-1 rounded-md">
                      <MdHourglassBottom size={15} className="" />
                    </span>
                    <span className="ml-2 text-lg mr-1">
                    {
                      (()=>{
                        let count  = 0;
                        for(let prospect of singleCampaign?.prospectsWiseCampaignActions){
                          if(prospect.campaignActions.length > 0 && prospect.campaignActions[index].done !== "DONE"){
                            count++;
                          }
                        }
                        return count;
                      })()
                    }
                    </span>
                    Prospects Waiting for Invitation
                  </p>
                  <div className="w-[2px] h-6 bg-blue-300  mx-auto"></div>
                  <p className="bg-green-500 flex items-center text-white w-full p-1 px-2 rounded-lg">
                    <span className="text-black bg-white p-1 rounded-md">
                      <FaPaperclip size={15} className="" />
                    </span>
                    <span className="ml-2 text-lg mr-1">
                    {
                      (()=>{
                        let count  = 0;
                        for(let prospect of singleCampaign?.prospectsWiseCampaignActions){
                          if(prospect.campaignActions.length > 0 && prospect.connected == "ACCEPTED"){
                            count++;
                          }
                        }
                        return count;
                      })()
                    }
                    </span>
                    Prospects Accepted Invitation
                  </p>
                </div>
              )}
              {action.type === "visit" && (
                <div className="flex flex-col my-2">
                  <div className="mb-2 flex justify-center items-center gap-2">
                    <div className="w-1/2 h-[1px] bg-gray-600"></div>
                    <p className="font-semibold uppercase">Visit</p>
                    <div className="w-1/2 h-[1px] bg-gray-600"></div>
                  </div>
                  <p className="bg-green-500 flex items-center text-white w-full p-1 px-2 rounded-lg">
                    <span className="text-black bg-white p-1 rounded-md">
                      <BsEye size={15} className="" />
                    </span>
                    <span className="ml-2 text-lg mr-1">
                    {
                      // calculate profile visited
                      (()=>{
                        let count  = 0;
                        for(let prospect of singleCampaign?.prospectsWiseCampaignActions){
                          if(prospect.campaignActions.length > 0 && prospect.campaignActions[index].done == "DONE"){
                            count++;
                          }
                        }
                        return count;
                      })()
                    }
                    </span>
                    Profiles Visited
                  </p>

                  <div className="w-[2px] h-6 bg-blue-300  mx-auto"></div>
                  <p className="bg-orange-400 flex items-center text-white w-full p-1 px-2 rounded-lg">
                    <span className="text-black bg-white p-1 rounded-md">
                      <MdHourglassBottom size={15} className="" />
                    </span>
                    <span className="ml-2 text-lg mr-1">
                    {
                      // calculate profile visited
                      (()=>{
                        let count  = 0;
                        for(let prospect of singleCampaign?.prospectsWiseCampaignActions){
                          if(prospect.campaignActions.length == 0 || prospect.campaignActions[index].done !== "DONE"){
                            count++;
                          }
                        }
                        return count;
                      })()
                    }
                    </span>
                    Profile Remaining to Visit
                  </p>
                </div>
              )}
              {action.type === "message" && (
                <div className="flex flex-col my-2">
                  <div className="mb-2 flex justify-center items-center gap-2">
                    <div className="w-1/2 h-[1px] bg-gray-600"></div>
                    <p className="font-semibold uppercase">Message</p>
                    <div className="w-1/2 h-[1px] bg-gray-600"></div>
                  </div>
                  <p className="bg-green-500 flex items-center text-white w-full p-1 px-2 rounded-lg">
                    <span className="text-black bg-white p-1 rounded-md">
                      <MdMessage size={15} className="" />
                    </span>
                    <span className="ml-2 text-lg mr-1">
                    {
                      (()=>{
                        let count  = 0;
                        for(let prospect of singleCampaign?.prospectsWiseCampaignActions){
                          if(prospect.campaignActions.length > 0 && prospect.campaignActions[index].done == "DONE"){
                            count++;
                          }
                        }
                        return count;
                      })()
                    }
                    </span>
                    Message Sent
                  </p>

                  <div className="w-[2px] h-6 bg-blue-300  mx-auto"></div>
                  <p className="bg-orange-400 flex items-center text-white w-full p-1 px-2 rounded-lg">
                    <span className="text-black bg-white p-1 rounded-md">
                      <MdHourglassBottom size={15} className="" />
                    </span>
                    <span className="ml-2 text-lg mr-1">
                    {
                      (()=>{
                        let count  = 0;
                        for(let prospect of singleCampaign?.prospectsWiseCampaignActions){
                          if(prospect.campaignActions.length > 0 && prospect.campaignActions[index].done !== "DONE"){
                            count++;
                          }
                        }
                        return count;
                      })()
                    }
                    </span>
                    Message Remaining to Send
                  </p>
                  <div className="w-[2px] h-6 bg-blue-300  mx-auto"></div>
                  <p className="bg-blue-500 flex items-center text-white w-full p-1 px-2 rounded-lg">
                    <span className="text-black bg-white p-1 rounded-md">
                      <MdCheck size={15} className="" />
                    </span>
                    <span className="ml-2 text-lg mr-1">
                    {
                      (()=>{
                        let count  = 0;
                        for(let prospect of singleCampaign?.prospectsWiseCampaignActions){
                          if(prospect.hasReplied){
                            count++;
                          }
                        }
                        return count;
                      })()
                    }
                    </span>
                    Prospects Replied
                  </p>
                </div>
              )}
              {action.type === "visitWithDelay" && (
                <div className="flex flex-col my-2">
                  <div className="mb-2 flex justify-center items-center gap-2">
                    <div className="w-1/2 h-[1px] bg-gray-600"></div>
                    <p className="font-semibold uppercase whitespace-nowrap">
                      Delayed Visit
                    </p>
                    <div className="w-1/2 h-[1px] bg-gray-600"></div>
                  </div>
                  <p className="bg-green-500 flex items-center text-white w-full p-1 px-2 rounded-lg">
                    <span className="text-black bg-white p-1 rounded-md">
                      <BsEye size={15} className="" />
                    </span>
                    <span className="ml-2 text-lg mr-1">
                    {
                      (()=>{
                        let count  = 0;
                        for(let prospect of singleCampaign?.prospectsWiseCampaignActions){
                          if(prospect.campaignActions.length > 0 && prospect.campaignActions[index].done == "DONE"){
                            count++;
                          }
                        }
                        return count;
                      })()
                    }
                    </span>
                    Profile Visited
                  </p>

                  <div className="w-[2px] h-6 bg-blue-300  mx-auto"></div>
                  <p className="bg-blue-400 flex items-center text-white w-full p-1 px-2 rounded-lg">
                    <span className="text-black bg-white p-1 rounded-md">
                      <CiClock1 size={15} className="" />
                    </span>
                    <span className="ml-2 text-lg mr-1">
                    {
                      (()=>{
                        let count  = 0;
                        for(let prospect of singleCampaign?.prospectsWiseCampaignActions){
                          if(prospect.campaignActions.length > 0 && prospect.campaignActions[index].done == "SCHEDULED"){
                            count++;
                          }
                        }
                        return count;
                      })()
                    }
                    </span>
                    Profile Scheduled to Visit
                  </p>
                </div>
              )}
              {action.type === "messageWithDelay" && (
                <div className="flex flex-col my-2">
                  <div className="mb-2 flex justify-center items-center gap-2">
                    <div className="w-1/2 h-[1px] bg-gray-600"></div>
                    <p className="font-semibold uppercase whitespace-nowrap text-xs">
                      Delayed Message
                    </p>
                    <div className="w-1/2 h-[1px] bg-gray-600"></div>
                  </div>
                  <p className="bg-green-500 flex items-center text-white w-full p-1  px-2 rounded-lg">
                    <span className="text-black bg-white p-1 rounded-md">
                      <BsEye size={15} className="" />
                    </span>
                    <span className="ml-2 text-lg mr-1">
                    {
                      (()=>{
                        let count  = 0;
                        for(let prospect of singleCampaign?.prospectsWiseCampaignActions){
                          if(prospect.campaignActions.length > 0 && prospect.campaignActions[index].done == "DONE"){
                            count++;
                          }
                        }
                        return count;
                      })()
                    }
                    </span>
                    Message Sent
                  </p>

                  <div className="w-[2px] h-6 bg-blue-300  mx-auto"></div>
                  <p className="bg-blue-400 flex items-center text-white w-full p-1 px-2 rounded-lg">
                    <span className="text-black bg-white p-1 rounded-md">
                      <CiClock1 size={15} className="" />
                    </span>
                    <span className="ml-2 text-lg mr-1">
                    {
                      (()=>{
                        let count  = 0;
                        for(let prospect of singleCampaign?.prospectsWiseCampaignActions){
                          if(prospect.campaignActions.length > 0 && prospect.campaignActions[index].done == "SCHEDULED"){
                            count++;
                          }
                        }
                        return count;
                      })()
                    }
                    </span>
                    Message Scheduled
                  </p>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Overview;
