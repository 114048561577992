import React, { useState } from 'react';
import './PurchaseCreditsModal.css';

interface PurchaseCreditsModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const PurchaseCreditsModal: React.FC<PurchaseCreditsModalProps> = ({ isOpen, onRequestClose }) => {
  const [isYearly, setIsYearly] = useState(false);

  if (!isOpen) return null;

  const togglePlan = () => {
    setIsYearly(!isYearly);
  };

  return (
    <div className="purchase-credits-modal">
      <div className="modal-content" style={{ 
        background: '#fff',
        borderRadius: '16px',
        padding: '32px',
        boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
      }}>
        <button className="close-button" onClick={onRequestClose} style={{
          background: '#f5f5f5',
          borderRadius: '50%',
          width: '32px',
          height: '32px',
          color: '#333',
          border: 'none',
          position: 'absolute',
          right: '24px',
          top: '24px'
        }}>
          &times;
        </button>
        <div className="modal-body" style={{ gap: '32px', padding: '24px 0' }}>
          <div className="modal-text" style={{ color: '#333' }}>
            <h2 style={{ fontSize: '28px', marginBottom: '24px', color: '#333' }}>LeadSeeder Teams Pricing</h2>
            <div className="plan-toggle" style={{ 
              background: '#f0faf5',
              padding: '12px',
              borderRadius: '24px',
              marginBottom: '24px',
              display: 'flex',
              alignItems: 'center',
              width: 'fit-content'
            }}>
              <span className={`toggle ${isYearly ? 'active' : ''}`} onClick={togglePlan} style={{
                background: isYearly ? '#74a567' : '#e2e8f0',
                width: '56px',
                height: '28px',
                borderRadius: '14px',
                position: 'relative',
                transition: 'background 0.3s ease',
                cursor: 'pointer'
              }}>
                <span className="toggle-indicator" style={{
                  background: '#fff',
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  position: 'absolute',
                  top: '2px',
                  left: isYearly ? '0px' : '2px',
                  transition: 'left 0.3s ease',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }} />
              </span>
              <span className="toggle-label" style={{ marginLeft: '12px', color: '#333', fontWeight: '500' }}>
                {isYearly ? 'Yearly Plan (Save 16.67%)' : 'Monthly Plan'}
              </span>
            </div>
            <div style={{ 
              background: '#f0faf5',
              padding: '24px',
              borderRadius: '12px',
              marginBottom: '24px',
              border: '1px solid #74a567',
              position: 'relative',
              overflow: 'hidden'
            }}>
              <div style={{
                position: 'absolute',
                top: '-50px',
                right: '-50px',
                width: '150px',
                height: '150px',
                background: '#74a567',
                borderRadius: '50%',
                opacity: 0.1
              }} />
              <p style={{ 
                fontSize: '24px',
                marginBottom: '8px',
                color: '#74a567',
                fontWeight: '700',
                textAlign: 'center',
                position: 'relative',
                zIndex: 1
              }}>
                {isYearly ? '$170/year' : '$17/month'}
              </p>
              <p style={{ 
                fontSize: '16px',
                marginBottom: '0',
                color: '#64748b',
                fontWeight: '500',
                textAlign: 'center',
                textDecoration: 'line-through',
                position: 'relative',
                zIndex: 1
              }}>
                {isYearly ? '$204/year' : ''}
              </p>
            </div>
            <div style={{ 
              background: '#f5f5f5',
              padding: '24px',
              borderRadius: '12px',
              marginBottom: '24px',
              position: 'relative',
              overflow: 'hidden'
            }}>
              <div style={{
                position: 'absolute',
                bottom: '-50px',
                left: '-50px',
                width: '150px',
                height: '150px',
                background: '#74a567',
                borderRadius: '50%',
                opacity: 0.1
              }} />
              <h3 style={{ 
                fontSize: '18px', 
                marginBottom: '16px', 
                color: '#333',
                position: 'relative',
                zIndex: 1
              }}>
                Everything You Get:
              </h3>
              <ul className="features" style={{ 
                listStyle: 'none', 
                padding: 0,
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '16px',
                position: 'relative',
                zIndex: 1
              }}>
                {[
                  'Collaborate & Share',
                  'Centralized account management',
                  'Cross-account lead import',
                  'Anti-Duplication Security',
                  'Access to other Team accounts',
                  'Upgrade and Create your Team'
                ].map((feature, index) => (
                  <li key={index} style={{ 
                    marginBottom: '0', 
                    display: 'flex', 
                    alignItems: 'center',
                    fontSize: '14px',
                    color: '#555',
                    background: '#fff',
                    padding: '12px',
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                  }}>
                    <svg width="18" height="18" viewBox="0 0 24 24" fill="#74a567" style={{ marginRight: '12px' }}>
                      <path d="M20 6L9 17l-5-5" stroke="#74a567" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="modal-footer" style={{ 
              marginTop: '32px', 
              gap: '16px',
              display: 'flex',
              justifyContent: 'center'
            }}>
              <button className="buy-button" onClick={() => {
                const url = isYearly ? 'https://buy.stripe.com/7sIcOz3AP15u8Ao007' : 'https://buy.stripe.com/fZecOzdbpcOc2c06ou';
                window.open(url, '_blank');
              }} style={{
                background: '#74a567',
                color: '#fff',
                padding: '12px 32px',
                borderRadius: '8px',
                border: 'none',
                fontSize: '16px',
                fontWeight: '600',
                minWidth: '200px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                transition: 'all 0.2s ease',
                cursor: 'pointer',
              }}>
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                  <line x1="3" y1="6" x2="21" y2="6"></line>
                  <path d="M16 10a4 4 0 0 1-8 0"></path>
                </svg>
                Buy Credit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseCreditsModal;

