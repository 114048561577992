import { Checkbox, Popover, Table, TableColumnType, TableProps } from "antd";
import { useEffect, useRef, useState } from "react";
import { FaChartSimple, FaPencil, FaPeopleGroup } from "react-icons/fa6";
import { IoIosLeaf } from "react-icons/io";

import { MdCancel, MdCheck, MdHourglassBottom, MdPause, MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  campaignSelector,
  dashboardSelector,
  ownerSelector,
  RootState,
} from "../../store/store";
import axios from "../../utils/axios";
import { RxAvatar } from "react-icons/rx";
import ProfileModal from "../Modals/ProfileModal";
import { setCurrentProspect } from "../../store/slices/prospectsSlice";
import { useNavigate } from "react-router-dom";
import { setListId } from "../../store/slices/ownerSlice";
import { toast } from "react-toastify";
import { BsClock } from "react-icons/bs";
import { Action, Campaign, Prospect, Tag } from "../../types";
import { Action as ReduxAction } from "@reduxjs/toolkit";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { fetchCampaignData } from "../../store/features/campaignsFeature";
import { CiSearch } from "react-icons/ci";
import Overview from "./Overview";
import { removeProspectFromCampaign } from "../../store/slices/campaignSlice";

type Props = {
  isMounted: boolean;
  setIsMounted: (isMounted: boolean) => void;
  onClose: () => void;
};


const CampaignModal = (props: Props) => {
  const { campaignId, owner } = useSelector(ownerSelector);
  const { singleCampaign } = useSelector(campaignSelector);
  const [campaignActions, setCampaignActions] = useState<Action[]>(
    [] as Action[]
  );
  const { campaignsData } = useSelector(dashboardSelector);
  const [numberOfActionsSent, setNumberOfActionsSent] = useState(0);
  const [numberOfPendingActions, setNumberOfPendingActions] = useState(0);
  const [prospectsWiseActions, setProspectsWiseActions] = useState(
    singleCampaign.prospectsWiseCampaignActions
  );
  const [filterOptions, setFilterOptions] = useState<string[]>([]);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("prospects");
  const [selectedInsight, setSelectedInsight] = useState("overview");
  const dispatch: ThunkDispatch<RootState, undefined, ReduxAction> =
    useDispatch();
  const navigate = useNavigate();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [prospectToRemove, setProspectToRemove] = useState<{id: string, name: string} | null>(null);

  const campaignModalRef = useRef<HTMLDivElement>(null);
  const filterDropdownRef = useRef<HTMLDivElement>(null);
  // useEffect(() => {
  //   if (showProfileModal) return;
  //   const handleClickOutside = (e: MouseEvent) => {
  //     if (filterDropdownRef.current?.contains(e.target as Node)) return;
  //     if (
  //       campaignModalRef.current &&
  //       !campaignModalRef.current.contains(e.target as Node) &&
  //       !filterDropdownRef.current?.contains(e.target as Node)
  //     ) {
  //       props.onClose();
  //     }
  //   };
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [props, showProfileModal]);

  useEffect(() => {
    const handleFilter = () => {
      if (filterOptions.length === 0) {
        setProspectsWiseActions(singleCampaign.prospectsWiseCampaignActions);
        return;
      }

      let filteredProspects = [...singleCampaign.prospectsWiseCampaignActions];

      filterOptions.forEach(option => {
        switch(option) {
          case "SKIPPED":
            filteredProspects = filteredProspects.filter(
              (prospect: any) => prospect.inCampaign === false
            );
            break;
          case "INVITATION_SENT":
            filteredProspects = filteredProspects.filter(
              (prospect: any) => prospect.connected === "PENDING" && prospect.inCampaign === true
            );
            break;
          case "INVITATION_PENDING":
            filteredProspects = filteredProspects.filter(
              (prospect: any) => prospect.connected === "NOT_STARTED" && prospect.inCampaign === true
            );
            break;
          case "INVITATION_ACCEPTED":
            filteredProspects = filteredProspects.filter(
              (prospect: any) => prospect.connected === "ACCEPTED" && prospect.inCampaign === true
            );
            break;
          case "PROFILE_VISITED":
            filteredProspects = filteredProspects.filter((prospect: any) =>
              prospect.campaignActions.find(
                (action: any) => action.type === "visit" && action.done === "DONE"
              ) && prospect.inCampaign === true
            );
            break;
          case "VISIT_PENDING":
            filteredProspects = filteredProspects.filter((prospect: any) =>
              prospect.campaignActions.find(
                (action: any) =>
                  action.type === "visit" && (action.done === "NOT_STARTED" || !action.done)
              ) && prospect.inCampaign === true
            );
            break;
          case "MESSAGE_SENT":
            filteredProspects = filteredProspects.filter((prospect: any) =>
              prospect.campaignActions.find(
                (action: any) => action.type === "message" && action.done === "DONE"
              ) && prospect.inCampaign === true
            );
            break;
          case "MESSAGE_PENDING":
            filteredProspects = filteredProspects.filter((prospect: any) =>
              prospect.campaignActions.find(
                (action: any) =>
                  action.type === "message" && (action.done === "NOT_STARTED" || !action.done)
              ) && prospect.inCampaign === true
            );
            break;
          case "MESSAGE_SCHEDULED":
            filteredProspects = filteredProspects.filter((prospect: any) =>
              prospect.campaignActions.find(
                (action: any) =>
                  action.type === "messageWithDelay" && action.done === "SCHEDULED"
              ) && prospect.inCampaign === true
            );
            break;
          case "HASREPLIED":
            filteredProspects = filteredProspects.filter((prospect: any) =>
              prospect.campaignActions.find(
                (action: any) =>
                  (action.type === "messageWithDelay" || action.type === "message") && action.done === "HASREPLIED"
              ) && prospect.inCampaign === true
            );
            break;
          case "DELAYED_MESSAGE_SENT":
            filteredProspects = filteredProspects.filter((prospect: any) =>
              prospect.campaignActions.find(
                (action: any) =>
                  action.type === "messageWithDelay" && action.done === "DONE"
              ) && prospect.inCampaign === true
            );
            break;
          case "DELAYED_MESSAGE_PENDING":
            filteredProspects = filteredProspects.filter((prospect: any) =>
              prospect.campaignActions.find(
                (action: any) =>
                  action.type === "messageWithDelay" &&
                  (action.done === "NOT_STARTED" || !action.done)
              ) && prospect.inCampaign === true
            );
            break;
          case "VISIT_SCHEDULED":
            filteredProspects = filteredProspects.filter((prospect: any) =>
              prospect.campaignActions.find(
                (action: any) =>
                  action.type === "visitWithDelay" && action.done === "SCHEDULED"
              ) && prospect.inCampaign === true
            );
            break;
          case "DELAYED_VISIT_DONE":
            filteredProspects = filteredProspects.filter((prospect: any) =>
              prospect.campaignActions.find(
                (action: any) =>
                  action.type === "visitWithDelay" && action.done === "DONE"
              ) && prospect.inCampaign === true
            );
            break;
          case "DELAYED_VISIT_PENDING":
            filteredProspects = filteredProspects.filter((prospect: any) =>
              prospect.campaignActions.find(
                (action: any) =>
                  action.type === "visitWithDelay" && (action.done === "NOT_STARTED" || !action.done)
              ) && prospect.inCampaign === true
            );
            break;
        }
      });

      setProspectsWiseActions(filteredProspects);
    };

    handleFilter();
  }, [filterOptions, singleCampaign, campaignsData]);
  useEffect(() => {
    (async () => {
      try {
        // const loader = toast.loading("Fetching campaign data...");
        const response = await dispatch(fetchCampaignData(campaignId));
        if (response.meta.requestStatus === "fulfilled") {
          // toast.dismiss(loader);
          // toast.success("Campaign fetched successfully");
          // console.log(response.payload.campaign);

          setCampaignActions(singleCampaign.actions);

          const actionsSent =
            singleCampaign.prospectsWiseCampaignActions.filter(
              (prospect: any) =>
                prospect.campaignActions.find(
                  (action: any) => action.done === "DONE"
                )
            );
          const totalActions =
            singleCampaign.actions.length * singleCampaign.prospects.length;
          props.setIsMounted(true);
          setNumberOfActionsSent(actionsSent.length);
          setNumberOfPendingActions(totalActions - actionsSent.length);
        }
      } catch (error: any) {
        console.log(error);
      }
    })();
  }, [numberOfActionsSent, numberOfPendingActions]);

  let actionColumns: TableColumnType<any>[] = [];

  singleCampaign.actions.forEach((action, index) => {
    if (action.type === "messageWithDelay") {
      actionColumns.push({
        title: "DELAYED MESSAGE",
        dataIndex: `${index}`,
        key: `${index}`,
        filterDropdown: (
          <div ref={filterDropdownRef} className="p-4 flex flex-col">
            <>
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterOptions([...filterOptions, "MESSAGE_SCHEDULED"]);
                  } else {
                    setFilterOptions(
                      filterOptions.filter(
                        (item) => item !== "MESSAGE_SCHEDULED"
                      )
                    );
                  }
                }}
              >
                Message Scheduled
              </Checkbox>
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterOptions([
                      ...filterOptions,
                      "DELAYED_MESSAGE_SENT",
                    ]);
                  } else {
                    setFilterOptions(
                      filterOptions.filter(
                        (item) => item !== "DELAYED_MESSAGE_SENT"
                      )
                    );
                  }
                }}
              >
                Message Sent
              </Checkbox>
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterOptions([
                      ...filterOptions,
                      "DELAYED_MESSAGE_PENDING",
                    ]);
                  } else {
                    setFilterOptions(
                      filterOptions.filter(
                        (item) => item !== "DELAYED_MESSAGE_PENDING"
                      )
                    );
                  }
                }}
              >
                Message Pending
              </Checkbox>
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterOptions([
                      ...filterOptions,
                      "HASREPLIED",
                    ]);
                  } else {
                    setFilterOptions(
                      filterOptions.filter(
                        (item) => item !== "HASREPLIED"
                      )
                    );
                  }
                }}
              >
                Has Replied
              </Checkbox>
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterOptions([
                      ...filterOptions,
                      "SKIPPED",
                    ]);
                  } else {
                    setFilterOptions(
                      filterOptions.filter(
                        (item) => item !== "SKIPPED"
                      )
                    );
                  }
                }}
              >
                Skipped
              </Checkbox>
            </>
          </div>
        ),
      });
    } else if (action.type === "visitWithDelay") {
      actionColumns.push({
        title: "Delayed Visit",
        dataIndex: `${index}`,
        key: `${index}`,
        filterDropdown: (
          <div ref={filterDropdownRef} className="p-4 flex flex-col">
            <>
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterOptions([...filterOptions, "VISIT_SCHEDULED"]);
                  } else {
                    setFilterOptions(
                      filterOptions.filter((item) => item !== "VISIT_SCHEDULED")
                    );
                  }
                }}
              >
                Visit Scheduled
              </Checkbox>
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterOptions([...filterOptions, "DELAYED_VISIT_DONE"]);
                  } else {
                    setFilterOptions(
                      filterOptions.filter(
                        (item) => item !== "DELAYED_VISIT_DONE"
                      )
                    );
                  }
                }}
              >
                Visit Done
              </Checkbox>
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterOptions([
                      ...filterOptions,
                      "DELAYED_VISIT_PENDING",
                    ]);
                  } else {
                    setFilterOptions(
                      filterOptions.filter(
                        (item) => item !== "DELAYED_VISIT_PENDING"
                      )
                    );
                  }
                }}
              >
                Visit Pending
              </Checkbox>
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterOptions([
                      ...filterOptions,
                      "SKIPPED",
                    ]);
                  } else {
                    setFilterOptions(
                      filterOptions.filter(
                        (item) => item !== "SKIPPED"
                      )
                    );
                  }
                }}
              >
                Skipped
              </Checkbox>
            </>
          </div>
        ),
      });
    } else {
      actionColumns.push({
        title: action.type.toUpperCase(),
        dataIndex: `${index}`,
        key: `${index}`,
        filterDropdown: (
          <div ref={filterDropdownRef} className="p-4 flex flex-col">
            {action.type === "invitation" && (
              <>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFilterOptions([...filterOptions, "INVITATION_SENT"]);
                    } else {
                      setFilterOptions((prev) =>
                        prev.filter((item) => item !== "INVITATION_SENT")
                      );
                    }
                  }}
                >
                  Invitation Sent
                </Checkbox>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFilterOptions([
                        ...filterOptions,
                        "INVITATION_PENDING",
                      ]);
                    } else {
                      setFilterOptions((prev) =>
                        prev.filter((item) => item !== "INVITATION_PENDING")
                      );
                    }
                  }}
                >
                  Invitation Pending
                </Checkbox>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFilterOptions([
                        ...filterOptions,
                        "INVITATION_ACCEPTED",
                      ]);
                    } else {
                      setFilterOptions((prev) =>
                        prev.filter((item) => item !== "INVITATION_ACCEPTED")
                      );
                    }
                  }}
                >
                  Invitation Accepted
                </Checkbox>
                <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterOptions([
                      ...filterOptions,
                      "SKIPPED",
                    ]);
                  } else {
                    setFilterOptions(
                      filterOptions.filter(
                        (item) => item !== "SKIPPED"
                      )
                    );
                  }
                }}
              >
                Skipped
              </Checkbox>
              </>
            )}
            {action.type === "visit" && (
              <>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFilterOptions([...filterOptions, "PROFILE_VISITED"]);
                    } else {
                      setFilterOptions((prev) =>
                        prev.filter((item) => item !== "PROFILE_VISITED")
                      );
                    }
                  }}
                >
                  Visit Done
                </Checkbox>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFilterOptions([...filterOptions, "VISIT_PENDING"]);
                    } else {
                      setFilterOptions((prev) =>
                        prev.filter((item) => item !== "VISIT_PENDING")
                      );
                    }
                  }}
                >
                  Visit Pending
                </Checkbox>
                <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterOptions([
                      ...filterOptions,
                      "SKIPPED",
                    ]);
                  } else {
                    setFilterOptions(
                      filterOptions.filter(
                        (item) => item !== "SKIPPED"
                      )
                    );
                  }
                }}
              >
                Skipped
              </Checkbox>
              </>
            )}
            {action.type === "message" && (
              <>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFilterOptions([...filterOptions, "MESSAGE_SENT"]);
                    } else {
                      setFilterOptions(
                        filterOptions.filter((item) => item !== "MESSAGE_SENT")
                      );
                    }
                  }}
                >
                  Message Sent
                </Checkbox>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFilterOptions([...filterOptions, "MESSAGE_PENDING"]);
                    } else {
                      setFilterOptions(
                        filterOptions.filter(
                          (item) => item !== "MESSAGE_PENDING"
                        )
                      );
                    }
                  }}
                >
                  Message Pending
                </Checkbox>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFilterOptions([...filterOptions, "HASREPLIED"]);
                    } else {
                      setFilterOptions(
                        filterOptions.filter(
                          (item) => item !== "HASREPLIED"
                        )
                      );
                    }
                  }}
                >
                 Has Replied
                </Checkbox>
                <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterOptions([
                      ...filterOptions,
                      "SKIPPED",
                    ]);
                  } else {
                    setFilterOptions(
                      filterOptions.filter(
                        (item) => item !== "SKIPPED"
                      )
                    );
                  }
                }}
              >
                Skipped
              </Checkbox>
              </>
            )}
          </div>
        ),
      });
    }
  });

  const campaignTableColumns: TableColumnType<any>[] = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    ...actionColumns,
    {
      title: "TAGS",
      dataIndex: "tags",
      key: "tags",
    },
    {
      title: "",
      dataIndex: "removeFromCampaign",
      key: "removeFromCampaign",
    },
  ];

  const campaignTableData: any = [];
  console.log("here");

  prospectsWiseActions.forEach((prospect, index) => {
    let temp: any = {
      key: prospect._id,
      name: (
        <div
          onClick={() => {
            dispatch(setCurrentProspect(prospect));
            setShowProfileModal(true);
          }}
          className="flex items-center gap-2 font-primary cursor-pointer"
        >
          {prospect.image_url ? (
            <img
              src={prospect.image_url}
              alt=""
              className="w-8 h-8 rounded-full"
            />
          ) : (
            <RxAvatar
              className="w-10 h-10 text-mainColor cursor-pointer"
              // size={20}
            />
          )}
          <p className="text-xs">
            {prospect.full_name?.length > 20 ? (
              <Popover content={prospect.full_name}>
                {prospect.full_name.slice(0, 20) + "..."}
              </Popover>
            ) : (
              prospect.full_name
            )}
          </p>
        </div>
      ),
      tags: (
        <div className="flex gap-2">
          {prospect.tags.length > 0 ? (
            prospect.tags.map((tag: Tag, index: number) => (
              <div key={index} className="flex items-center gap-2">
                <p
                  style={{ backgroundColor: tag.color }}
                  className={`text-xs py-0.5 px-2 cursor-pointer rounded-xl text-white whitespace-nowrap`}
                >
                  {tag.name}
                </p>
              </div>
            ))
          ) : (
            <p className="text-xs p-1 whitespace-nowrap text-center bg-gray-500/50 text-white rounded-md">
              No tags
            </p>
          )}
        </div>
      ),
      removeFromCampaign: (
        prospect.inCampaign ? (
          <div className="flex items-center justify-center">
            <button
              onClick={() => handleRemoveProspect(prospect)}
              className="text-xs p-1 bg-red-500/80 text-white rounded-md hover:bg-red-600/80 transition-colors flex items-center gap-1"
            >
              <MdDelete size={15} />
              Remove
            </button>
          </div>
        ) : (
          <div className="flex items-center justify-center">
            <p className="text-xs p-1 bg-gray-500/80 text-white rounded-md">
              Not in Campaign
            </p>
          </div>
        )
      ),
    };

    let hasReplied = false;
    prospect.campaignActions.forEach((action: any, index: number) => {
      if (action.done == "HASREPLIED") {
        hasReplied = true;
      }
      if(prospect.inCampaign == false){
        temp[`${index}`] = (
          <div className="flex items-center gap-2 font-primary">
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-gray-500/80 flex gap-2 text-white rounded-md">
              <MdPause size={15} />
              <p>Skipped</p> 
            </p>
          </div>
        );
      } else if (hasReplied) {
        temp[`${index}`] = (
          <div className="flex items-center gap-2 font-primary">
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-green-500/80 flex gap-2 text-white rounded-md">
              <MdCheck size={15} />
              <p>Has Replied</p>
            </p>
          </div>
        );
      } else if(action.done == "Error") {
        temp[`${index}`] = (
          <div className="flex items-center gap-2 font-primary">
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-red-500/80 flex gap-2 text-white rounded-md">
              <MdCancel size={15} />
              <p>Error</p>
            </p>
          </div>
        );
      } else if (action.type == "invitation") {
        temp[`${index}`] = (
          <div className="flex items-center gap-2 font-primary">
            {prospect.connected === "ACCEPTED" ? (
              <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-green-500/80 flex gap-2 text-white rounded-md">
                <MdCheck size={15} />
                <p>Invitation Accepted</p>
              </p>
            ) : prospect.connected === "PENDING" ? (
              <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-blue-500/80 flex gap-2 text-white rounded-md">
                <BsClock size={15} />

                <p>Invitation Sent</p>
              </p>
            ) : (
              <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-orange-500/80 flex gap-2 text-white rounded-md">
                <MdHourglassBottom size={15} />

                <p>Invitation Pending</p>
              </p>
            )}
          </div>
        );
      } else if (action.type == "visit") {
        if (action.done == "DONE") {
          temp[`${index}`] = (
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-green-500/80 flex gap-2 text-white rounded-md">
              <MdCheck size={15} />
              <p>Profile Visited</p>
            </p>
          );
        } else {
          temp[`${index}`] = (
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-orange-500/80 flex gap-2 text-white rounded-md">
              <MdHourglassBottom size={15} />
              <p>Visit Pending</p>
            </p>
          );
        }
      } else if (action.type == "message") {
        if (action.done == "DONE") {
          temp[`${index}`] = (
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-green-500/80 flex gap-2 text-white rounded-md">
              <MdCheck size={15} />
              <p>Message Sent</p>
            </p>
          );
        } else {
          temp[`${index}`] = (
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-orange-500/80 flex gap-2 text-white rounded-md">
              <MdHourglassBottom size={15} />
              <p>Message Pending</p>
            </p>
          );
        }
      } else if (action.type == "messageWithDelay") {
        if (action.done == "SCHEDULED") {
          temp[`${index}`] = (
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-blue-500/80 flex gap-2 text-white rounded-md">
              <MdHourglassBottom size={15} />
              <p>Message Scheduled</p>
            </p>
          );
        } else if (action.done == "DONE") {
          temp[`${index}`] = (
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-green-500/80 flex gap-2 text-white rounded-md">
              <MdCheck size={15} />
              <p>Message Sent</p>
            </p>
          );
        } else {
          temp[`${index}`] = (
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-orange-500/80 flex gap-2 text-white rounded-md">
              <MdHourglassBottom size={15} />
              <p>Message Pending</p>
            </p>
          );
        }
      } else if (action.type == "visitWithDelay") {
        if (action.done == "SCHEDULED") {
          temp[`${index}`] = (
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-blue-500/80 flex gap-2 text-white rounded-md">
              <MdHourglassBottom size={15} />
              <p>Visit Scheduled</p>
            </p>
          );
        } else if (action.done == "DONE") {
          temp[`${index}`] = (
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-green-500/80 flex gap-2 text-white rounded-md">
              <MdCheck size={15} />
              <p>Visit Done</p>
            </p>
          );
        } else {
          temp[`${index}`] = (
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-orange-500/80 flex gap-2 text-white rounded-md">
              <MdHourglassBottom size={15} />
              <p>Visit Pending</p>
            </p>
          );
        }
      }
    });

    campaignTableData.push(temp);
  });

  const handleSearchProspect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    if (searchValue === "") {
      setProspectsWiseActions(singleCampaign.prospectsWiseCampaignActions);
      return;
    }
    const filteredProspects = prospectsWiseActions.filter((prospect) =>
      prospect.full_name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setProspectsWiseActions(filteredProspects);
  };

  const handleRemoveProspect = (prospect: any) => {
    setProspectToRemove({
      id: prospect._id,
      name: prospect.full_name
    });
    setShowConfirmModal(true);
  };

  const confirmRemoveProspect = () => {
    if (prospectToRemove) {
      dispatch(removeProspectFromCampaign(JSON.stringify({
        prospectId: prospectToRemove.id,
        campaignName: singleCampaign.campaignName
      })));
    }
    setShowConfirmModal(false);
    setProspectToRemove(null);
  };

  if(!props.isMounted){
    // add a loader here
    return <div className="absolute top-0 left-0 z-50 w-full h-screen backdrop-blur-lg bg-black/30">
      <div className="flex flex-col items-center justify-center h-full">
        <div className="w-16 h-16 border-4 border-mainColor border-t-transparent rounded-full animate-spin"></div>
        <p className="mt-4 text-white text-lg font-medium animate-pulse">Loading campaign details...</p>
      </div>
    </div>
  }


  return (
    <div className="absolute top-0 left-0 z-50 w-full h-screen backdrop-blur-sm bg-black/20">
      <div>
        <div
          ref={campaignModalRef}
          className="bg-white w-[90%] max-w-[1200px] min-h-[90%] text-xs absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-lg p-6 shadow-xl"
        >
          <span
            className="text-mainColor absolute right-6 top-6 cursor-pointer hover:bg-gray-100 p-1 rounded-full transition-colors"
            onClick={props.onClose}
          >
            <MdCancel size={25} />
          </span>

          <div className="bg-mainColor/10 flex w-full items-center justify-between mt-8 p-6 rounded-lg border border-mainColor/20">
            <div className="flex items-center gap-4">
              <h1 className="font-semibold text-xl text-gray-800">
                {singleCampaign?.campaignName ?? ""}
              </h1>
              <div className="border border-mainColor/50 flex gap-2 p-2 rounded-lg items-center bg-white text-sm hover:border-mainColor transition-colors">
                <CiSearch size={20} className="text-gray-400" />
                <input
                  onChange={handleSearchProspect}
                  type="text"
                  placeholder="Search prospects"
                  className="outline-none w-full placeholder-gray-400"
                />
              </div>
            </div>
            <div className="flex justify-center items-center gap-2 bg-white px-3 py-1.5 rounded-full border border-gray-200">
              <p className="text-gray-600">Imported from</p>
              <p className="bg-mainColor text-white px-3 py-1 rounded-full text-sm font-medium">
                {singleCampaign?.listId?.listName ?? ""}
              </p>
            </div>
          </div>

          {selectedTab === "prospects" ? (
            <div className="flex w-full max-h-[80%] gap-4 bg-mainColor/10 mt-4 rounded-lg border border-mainColor/20">
              <div className="w-[25%] overflow-hidden flex flex-col items-center border-r border-mainColor/20 px-3 py-4">
                <div className="w-full font-semibold flex justify-around bg-white rounded-lg p-1 border border-gray-200">
                  <span
                    onClick={() => setSelectedInsight("overview")}
                    className={`${
                      selectedInsight === "overview" &&
                      "bg-mainColor hover:bg-mainColor/70 text-white"
                    } w-[50%] flex items-center justify-center p-2 rounded-md font-semibold cursor-pointer transition duration-300 text-sm`}
                  >
                    Overview
                  </span>
                  <span
                    onClick={() => setSelectedInsight("details")}
                    className={`${
                      selectedInsight === "details" &&
                      "bg-mainColor hover:bg-mainColor/70 text-white"
                    } w-[50%] flex items-center p-2 justify-center rounded-md font-semibold cursor-pointer transition duration-300 text-sm`}
                  >
                    Details
                  </span>
                </div>
                {!campaignsData ? (
                  <div className="h-full flex flex-col justify-center items-center bg-white my-2 w-full rounded-xl p-6 border border-gray-200">
                    <IoIosLeaf size={100} className="text-mainColor" />
                    <p className="text-lg font-semibold text-gray-700 mt-3">
                      Oh snap! Nothing to see.
                    </p>
                  </div>
                ) : selectedInsight === "overview" ? (
                  <Overview />
                ) : (
                  <div className="h-full gap-2 flex flex-col justify-start items-center bg-white my-2 w-full rounded-xl p-4 border border-gray-200">
                    <h1 className="font-semibold text-gray-700 mb-3">Action Details</h1>
                    <div className="w-full flex justify-between items-center p-3 bg-mainColor/10 rounded-lg">
                      <p className="font-semibold text-gray-600">Action</p>
                      <p className="font-semibold text-gray-600">Status</p>
                    </div>

                    {singleCampaign?.actions?.map(
                      (action: any, index: number) => (
                        <div
                          key={index}
                          className="w-full flex justify-between items-center p-2 bg-white rounded-lg"
                        >
                          {action.type === "messageWithDelay" ? (
                            <div className="w-full flex flex-col gap-4">
                              <div className="flex items-center  gap-4 w-full">
                                <p>Delay</p>
                                <p className="text-xs text-gray-400">
                                  {action.delay} days
                                </p>
                              </div>
                              <p>Message</p>
                            </div>
                          ) : action.type === "visitWithDelay" ? (
                            <div className="w-full flex flex-col gap-4">
                              <div className="flex items-center  gap-4 w-full">
                                <p>Delay</p>
                                <p className="text-xs text-gray-400">
                                  {action.delay} days
                                </p>
                              </div>
                              <p>Visit</p>
                            </div>
                          ) : (
                            <p>{action.type.toUpperCase()}</p>
                          )}

                          {singleCampaign.campaignStatus === "PAUSED" ||
                          singleCampaign.campaignStatus === "ARCHIVED" ||
                          singleCampaign.campaignStatus === "COMPLETED" ? (
                            action.done === "DONE" ? (
                              <Popover content={"Completed"}>
                                <MdCheck size={20} className="text-green-500" />
                              </Popover>
                            ) : singleCampaign.campaignStatus === "PAUSED" ? (
                              <Popover content={"Paused"}>
                                <MdPause
                                  size={20}
                                  className="text-yellow-500"
                                />
                              </Popover>
                            ) : (
                              singleCampaign.campaignStatus === "ARCHIVED" && (
                                <Popover content={"Cancelled"}>
                                  <MdCancel
                                    size={20}
                                    className="text-red-500"
                                  />
                                </Popover>
                              )
                            )
                          ) : (
                            <p>
                              {action.done === "DONE" ? (
                                <Popover content={"Completed"}>
                                  <MdCheck
                                    size={20}
                                    className="text-green-500"
                                  />
                                </Popover>
                              ) : action.done === "NOT_STARTED" ? (
                                <Popover content="In Progress">
                                  <MdHourglassBottom
                                    size={20}
                                    className="text-gray-500"
                                  />
                                </Popover>
                              ) : (
                                <MdCancel size={20} className="text-red-500" />
                              )}
                            </p>
                          )}
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
              <div className="w-[75%] bg-white flex justify-center items-center p-4">
                <div className="w-full relative h-[65vh] overflow-x-scroll slimScrollbar">
                  <Table
                    columns={campaignTableColumns}
                    dataSource={campaignTableData}
                    pagination={false}
                    className="slimScrollbar bg-white rounded-lg border border-gray-200"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full h-[50vh] justify-center flex my-auto items-center flex-col bg-white rounded-lg p-8 border border-gray-200 mt-4">
              <FaChartSimple size={50} className="text-mainColor" />
              <p className="font-semibold text-gray-700 mt-3">
                Analytics Coming Soon! Stay tuned.
              </p>
            </div>
          )}
        </div>
      </div>
      {showConfirmModal && (
        <div className="fixed inset-0 z-[60] flex items-center justify-center bg-black/50 backdrop-blur-sm">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 shadow-xl">
            <h3 className="text-lg font-semibold mb-4 text-gray-800">Confirm Removal</h3>
            <p className="mb-6 text-gray-600">
              Are you sure you want to remove <span className="font-semibold text-gray-800">{prospectToRemove?.name}</span> from this campaign?
            </p>
            <div className="flex justify-end gap-3">
              <button
                onClick={() => setShowConfirmModal(false)}
                className="px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 rounded-md transition-colors border border-gray-200"
              >
                Cancel
              </button>
              <button
                onClick={confirmRemoveProspect}
                className="px-4 py-2 text-sm text-white bg-red-500 hover:bg-red-600 rounded-md transition-colors"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      )}

      {showProfileModal && (
        <ProfileModal onClose={() => setShowProfileModal(false)} />
      )}
    </div>
  );
};

export default CampaignModal;
