import React, { useEffect, useState } from "react";
import BillingOverview from "../components/Billings/BillingOverview";
import PlanCard from "../components/Billings/PlanCard";
import PlanTable from "../components/Billings/PlanTable";
import { Switch } from "antd";

type Props = {};

const Billings = (props: Props) => {
  const [isAnnual, setIsAnnual] = useState(true);

  const plans = [
    {
      currencySymbol: "$",
      amount: 0,
      name: "Free",
      planId: "Free Plan",
      features: [
        "Cloud based solution",
        "Unlimited imports",
        "Reply detection",
        "Unlimited message templates",
        "Delete pending request",
        "Campaign analytics",
        "Activity control center",
        "Chat support",
      ],
      showDiscount: false,
      paymentUrl:
        "https://chromewebstore.google.com/detail/linkedin-automation-tool/ccabkgkocobdhpnlofbdonmdnpnnjaga?authuser=0&hl=en-GB&pli=1",
    },
    {
      currencySymbol: "$",
      amount: isAnnual ? 14.17 : 17.00,
      name: "Premium",
      planId: "Premium Plan",
      features: [
        "Cloud based solution",
        "Unlimited imports",
        "800 invitations/month",
        "200 messages/day",
        "Reply detection",
        "Unlimited message templates",
        "Unlimited campaigns",
        "Activity control center",
        "Sendspark video integration",
        "Delete pending request",
        "Campaign analytics",
        "Import/Export from CSV",
        "Priority support",
      ],
      discount: 16.67,
      showDiscount: isAnnual,
      paymentUrl: isAnnual
        ? "https://buy.stripe.com/bIY5m78V915u2c0eUZ"
        : "https://buy.stripe.com/4gw15R6N14hGg2QcMQ",
    },
  ];

  return (
    <section className="  p-4  overflow-y-scroll max-h-[90vh] noScroll">
      <div className="flex flex-col gap-4 w-[60%] mx-auto">
        <BillingOverview />

        <div className="flex justify-between">
          <h3 className=" font-semibold">Plans</h3>
          <div className="flex gap-2 items-center justify-center">
            <p className="text-sm">Change Plan:</p>
            <Switch
              checkedChildren="Yearly"
              unCheckedChildren="Monthly"
              checked={isAnnual}
              onChange={() => setIsAnnual((prev) => !prev)}
              className="bg-mainColor text-mainColor"
            />
          </div>
        </div>

        <div className="flex gap-4 items-center justify-center">
          {plans.map((plan, index) => (
            <PlanCard key={index} {...plan} />
          ))}
        </div>

        {/* <PlanTable /> */}
      </div>
    </section>
  );
};

export default Billings;
