import React, { useEffect, useState } from "react";
import { IoMdEye } from "react-icons/io";
import { MdInfo, MdInfoOutline, MdLink, MdMessage } from "react-icons/md";
import { ownerSelector, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { fetchOwner } from "../../utils";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { updateDailyQuota } from "../../store/features/ownerActions";
import { toast } from "react-toastify";
import { Popover } from "antd";
import { setOwner } from "../../store/slices/ownerSlice";
import { Select, TimePicker } from "antd";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { ClockCircleOutlined } from '@ant-design/icons';
import axios from "../../utils/axios";

type Props = {};

const quotas = [
  {
    title: "Invitations",
    key: "invitations",
    icon: <MdLink size={20} />,
  },
  {
    title: "Messages",
    key: "messages",
    icon: <MdMessage size={20} />,
  },

  {
    title: "Profile Visits",
    key: "visits",
    icon: <IoMdEye size={20} />,
  },
];

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const QuotaSettings = (props: Props) => {
  const { owner, loading } = useSelector(ownerSelector);
  const [dailyQuota, setDailyQuota] = useState(owner?.dailyQuota!);
  const [dailyUsage, setDailyUsage] = useState(owner?.dailyUsage!);
  const dispatch: ThunkDispatch<RootState, undefined, Action> = useDispatch();
  const [timePeriods, setTimePeriods] = useState<Record<string, { start: Dayjs | null; end: Dayjs | null; active: boolean }>>({});

  useEffect(() => {
    (async () => {
      const updatedOwner = await fetchOwner(owner?._id as string);
      setDailyQuota(updatedOwner.dailyQuota);
      setDailyUsage(updatedOwner.dailyUsage);
    const newPeriods = daysOfWeek.reduce((acc, day) => {
      return {
        ...acc,
        [day]: {
          start: updatedOwner?.timePeriods?.[day as keyof typeof updatedOwner.timePeriods]?.start ? dayjs(updatedOwner.timePeriods[day as keyof typeof updatedOwner.timePeriods].start) : null,
          end: updatedOwner?.timePeriods?.[day as keyof typeof updatedOwner.timePeriods]?.end ? dayjs(updatedOwner.timePeriods[day as keyof typeof updatedOwner.timePeriods].end) : null,
          active: updatedOwner?.timePeriods?.[day as keyof typeof updatedOwner.timePeriods]?.active ?? true
        }
      };
    }, {} as Record<string, { start: Dayjs | null; end: Dayjs | null; active: boolean }>);
    
    setTimePeriods(newPeriods);
    })();
  }, [owner?._id]);

  const handleQuotaUpdate = async (type: string) => {
    try {
      if (type === "RESET") {
        const reqData = {
          ownerId: owner?._id as string,
          dailyQuota: {
            invitations: 50,
            messages: 50,
            visits: 50,
            follows: 50,
          },
        };

        const res = await dispatch(updateDailyQuota(reqData));

        if (res.payload.success) {
          setDailyQuota(res.payload.data.dailyQuota);

          toast.success("Daily quota updated successfully");
        }
      } else {
        if (dailyQuota.invitations > 100) {
          toast.error("You can send max 100 invitations per day");
          return;
        }
        if (dailyQuota.messages > 100) {
          toast.error("You can send max 100 messages per day");
          return;
        }
        if (dailyQuota.visits > 200) {
          toast.error("You can visits max 200 profiles per day");
          return;
        }
        const reqData = {
          ownerId: owner?._id as string,
          dailyQuota: dailyQuota,
        };

        const res = await dispatch(updateDailyQuota(reqData));

        if (res.payload.success) {
          setDailyQuota(res.payload.data.dailyQuota);
          toast.success("Daily quota updated successfully");
          dispatch(setOwner(res.payload.data));
        } else {
          toast.error("Failed to update daily quota");
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to update daily quota");
    }
  };

  const updateTimePeriods = async (day: string, periods: { start: Dayjs | null; end: Dayjs | null; active: boolean }) => {
    try {
      const res = await axios.post('/owner/updateTimePeriods', {
        ownerId: owner?._id,
        day,
        periods
      });

      if (res.status !== 200) {
        throw new Error('Failed to update time periods');
      }

      // Optionally update state with the response if needed
      toast.success('Time periods updated successfully');
    } catch (error) {
      console.error(error);
      toast.error('Failed to update time periods');
    }
  };

  const handleTimeChange = async (day: string, type: 'start' | 'end' | 'active', value: Dayjs | null | boolean) => {
    const newPeriods = {
      ...timePeriods[day],
      [type]: value
    };
    
    // Add validation for start and end times
    if (type === 'start' && newPeriods.end && typeof value !== 'boolean' && value && value.isAfter(newPeriods.end)) {
      toast.error('Start time must be before end time');
      return;
    }
    if (type === 'end' && newPeriods.start && typeof value !== 'boolean' && value && value.isBefore(newPeriods.start)) {
      toast.error('End time must be after start time');
      return;
    }
    
    setTimePeriods(prev => ({
      ...prev,
      [day]: newPeriods
    }));

    // Make API call with updated periods
    await updateTimePeriods(day, newPeriods);
  };

  return (
    <section className="flex gap-6 flex-col h-[calc(100vh-200px)] overflow-y-auto">
      <div>
        <h1 className="text-lg font-semibold">Edit your max daily quota</h1>
        <small>
          Specific limit: Linkedin allows only 200 invitations max. per week
        </small>
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          <h2 className="font-semibold">Linkedin usage </h2>
          {/* <p>
            <Popover
              placement="right"
              content={
                <p className="text-xs">
                  Updated daily limit will be effective from the next day.
                </p>
              }
            >
              <MdInfoOutline size={15} />
            </Popover>
          </p> */}
          {owner?.usageError && (
            <p className="text-xs text-red-800 bg-red-200 p-1 rounded-lg">
              {owner?.usageError}
            </p>
          )}
        </div>
        <div className="flex gap-4">
          <div
            key={quotas[0].key}
            className="flex gap-4 justify-between items-center p-4 bg-white rounded-lg text-xs"
          >
            <span className="text-white rounded-full p-2 bg-mainColor">
              {quotas[0].icon}
            </span>
            <div className="flex flex-col gap-2">
              <p className="font-semibold uppercase">{quotas[0].title}</p>
              <div className="flex justify-center gap-2 items-center">
                <p>
                  Used <b>{dailyUsage && dailyUsage["invitations"]}</b> of
                </p>

                <input
                  min={1}
                  max={100}
                  type="number"
                  value={dailyQuota && dailyQuota["invitations"]}
                  onChange={(e) => {
                    setDailyQuota({
                      ...dailyQuota,
                      invitations: parseInt(e.target.value),
                    });
                  }}
                  className="border-2 w-12 appearance-none border-mainColor rounded-lg p-1 text-center"
                ></input>
                <p>/day</p>
              </div>
            </div>
          </div>
          <div
            key={quotas[1].key}
            className="flex gap-4 justify-between items-center p-4 bg-white rounded-lg text-xs"
          >
            <span className="text-white rounded-full p-2 bg-mainColor">
              {quotas[1].icon}
            </span>
            <div className="flex flex-col gap-2">
              <p className="font-semibold uppercase">{quotas[1].title}</p>
              <div className="flex justify-center gap-2 items-center">
                <p>
                  Used <b>{dailyUsage && dailyUsage["messages"]}</b> of
                </p>
                <input
                  type="number"
                  min={1}
                  max={100}
                  value={dailyQuota && dailyQuota["messages"]}
                  onChange={(e) => {
                    setDailyQuota({
                      ...dailyQuota,
                      messages: parseInt(e.target.value),
                    });
                  }}
                  className="border-2 w-12 appearance-none border-mainColor rounded-lg p-1 text-center"
                ></input>
                <p>/day</p>
              </div>
            </div>
          </div>
          <div
            key={quotas[2].key}
            className="flex gap-4 justify-between items-center p-4 bg-white rounded-lg text-xs"
          >
            <span className="text-white rounded-full p-2 bg-mainColor">
              {quotas[2].icon}
            </span>
            <div className="flex flex-col gap-2">
              <p className="font-semibold uppercase">{quotas[2].title}</p>
              <div className="flex justify-center gap-2 items-center">
                <p>
                  Used <b>{dailyUsage && dailyUsage["visits"]}</b> of
                </p>
                <input
                  type="number"
                  min={1}
                  max={100}
                  value={dailyQuota && dailyQuota["visits"]}
                  onChange={(e) => {
                    setDailyQuota({
                      ...dailyQuota,
                      visits: parseInt(e.target.value),
                    });
                  }}
                  className="border-2 w-12 border-mainColor rounded-lg p-1 text-center"
                ></input>
                <p>/day</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-4 text-xs">
          <button
            disabled={loading}
            onClick={() => handleQuotaUpdate("UPDATE")}
            className="bg-mainColor flex items-center justify-center gap-2 text-white p-2 text-xs rounded-md"
          >
            Update
          </button>
          <button
            disabled={loading}
            onClick={() => handleQuotaUpdate("RESET")}
            className="bg-mainColor flex items-center justify-center gap-2 text-white p-2 text-xs rounded-md"
          >
            Restore Default
          </button>
        </div>
      </div>

      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-6 text-gray-800">Active Time Periods</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-4">
          {daysOfWeek.map(day => (
            <div key={day} className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow border border-gray-100">
              <div className="flex justify-between items-center mb-4">
                <h3 className="font-medium text-gray-700 text-lg">{day}</h3>
                <label className="flex items-center gap-2 text-sm text-gray-600">
                  <div className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={timePeriods[day]?.active}
                      onChange={(e) => handleTimeChange(day, 'active', e.target.checked)}
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-mainColor"></div>
                  </div>
                  <span className="text-sm">Active</span>
                </label>
              </div>
              <div className={`space-y-4 ${!timePeriods[day]?.active ? 'opacity-50 pointer-events-none' : ''}`}>
                <div className="flex items-center gap-4">
                  <TimePicker
                    format="HH:mm"
                    value={timePeriods[day]?.start}
                    onChange={(value) => handleTimeChange(day, 'start', value)}
                    placeholder="Start Time"
                    className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-mainColor focus:border-mainColor transition-all"
                    popupClassName="time-picker-popup"
                    suffixIcon={<ClockCircleOutlined className="text-gray-400" />}
                    disabled={!timePeriods[day]?.active}
                  />
                </div>
                <div className="flex items-center gap-4">
                  <TimePicker
                    format="HH:mm"
                    value={timePeriods[day]?.end}
                    onChange={(value) => handleTimeChange(day, 'end', value)}
                    placeholder="End Time"
                    className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-mainColor focus:border-mainColor transition-all"
                    popupClassName="time-picker-popup"
                    suffixIcon={<ClockCircleOutlined className="text-gray-400" />}
                    disabled={!timePeriods[day]?.active}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default QuotaSettings;
