import React, { useEffect, useRef, useState } from "react";
import { FaPaperPlane } from "react-icons/fa6";
import { IoMdBulb } from "react-icons/io";
import { MdPerson } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { ownerSelector, RootState, templateSelector } from "../../store/store";
import {
  setSelectedTemplate,
  setTemplates,
} from "../../store/slices/templateSlice";
import { createTemplate } from "../../store/features/ownerActions";
import { toast } from "react-toastify";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { fetchTemplates } from "../../utils";
import {
  setActionsAdded,
  setNewCampaignData,
} from "../../store/slices/ownerSlice";

type Props = {
  idx: number,
  onClose: () => void;
};

const AddInvitationNoteModal = (props: Props) => {
  const { selectedTemplate, templates, loading } =
    useSelector(templateSelector);
  const { owner, newCampaignData, actionsAdded } = useSelector(ownerSelector);
  const [showInputNote, setShowNoteInput] = useState(props.idx < actionsAdded.length);
  const [onFocused, setOnFocused] = useState(false);
  const modalSelfRef = useRef<HTMLDivElement>(null);

  const dispatch: ThunkDispatch<RootState, undefined, Action> = useDispatch();

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        modalSelfRef.current &&
        !modalSelfRef.current.contains(e.target as Node)
      ) {
        props.onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    
  }, [props]);

  useEffect(()=>{
    if(props.idx < actionsAdded.length){
      dispatch(
        setSelectedTemplate({
          ...selectedTemplate,
          content: {
            ...selectedTemplate.content,
            body: newCampaignData.actions[props.idx].customMessage,
          },
        })
      );
    }
  },[]);

  const handleTemplateSave = async () => {
    if (selectedTemplate.name === "" || selectedTemplate.content.body === "") {
      toast.error("Template name and body cannot be empty");
      return;
    }

    if (selectedTemplate.content.body.length > 8000) {
      toast.error("Message body cannot be more than 8000 characters");
      return;
    }

    const res = await dispatch(createTemplate(selectedTemplate));

    if (res.meta.requestStatus === "fulfilled") {
      toast.success("Template saved successfully");
      const templates = await fetchTemplates(owner?._id as string);
      dispatch(setTemplates(templates));
      dispatch(setSelectedTemplate(templates[0]));
      // setAddingMessage(false);
      // setSelectingActions(true);
    } else {
      toast.error("Error saving template");
    }
  };
  return (
    <div className="absolute top-0 left-0 z-50 w-full h-screen backdrop-blur-sm bg-black/20">
      <div
        ref={modalSelfRef}
        className="bg-white items-center justify-center text-xs absolute top-1/2 left-1/2 transform  -translate-x-1/2 -translate-y-1/2 rounded-lg p-4 w-auto"
      >
        {showInputNote ? (
          <div className="p-4 flex w-full  justify- items-start">
            <div className="flex flex-col gap-4 items-start justify-start w-[900px] border-r-2 px-4">
              <div className="flex justify-center items-center font-semibold gap-2">
                <span className="bg-mainColor p-2 text-white text-center rounded-lg">
                  <FaPaperPlane size={20} />
                </span>
                <p>Edit message</p>
              </div>
              <div className="flex gap-2 w-full">
                <span className="w-full">
                <p style={{ fontWeight: 600, padding: "5px" }}>Name your message template</p>
                <input
                  value={selectedTemplate?.name || ""}
                  onChange={(e) => {
                    dispatch(
                      setSelectedTemplate({
                        ...selectedTemplate,
                        name: e.target.value,
                        content: {
                          ...selectedTemplate.content,
                          subject: e.target.value,
                        },
                      })
                    );
                  }}
                  type="text"
                  placeholder="Name your message template"
                  className="p-2 border-2 border-mainColor/20 w-full outline-none rounded-md"
                />
                </span>
                <span className="w-full">
                <p style={{ fontWeight: 600, padding: "5px" }}>Select a message template</p>
                <select
                  onChange={(e) => {
                    const selected = templates.find((template: any) => {
                      return template._id === e.target.value;
                    });
                    dispatch(setSelectedTemplate(selected));
                  }}
                  name=""
                  id=""
                  className="p-2 border-2 border-mainColor/20 w-full outline-none rounded-md"
                >
                  {templates.map((template: any) => (
                    <option key={template._id} value={template._id}>
                      {template?.name}
                    </option>
                  ))}
                </select>
                </span>
              </div>
              <div className="bg-primary w-full h-[60vh] border-mainColor/50 border-2 rounded-lg relative">
                <p className="absolute right-1 text-xs top-1 ">
                  {selectedTemplate?.content.body.length || 0}/300
                </p>
                <textarea
                  maxLength={300}
                  id="message"
                  onClick={() => setOnFocused(true)}
                  value={selectedTemplate?.content.body.length <= 300 
                    ? selectedTemplate?.content.body 
                    : (() => {
                        toast.error("Message cannot exceed 300 characters");
                        dispatch(
                          setSelectedTemplate({
                            ...selectedTemplate,
                            content: {
                              ...selectedTemplate.content,
                              body: ""
                            },
                          })
                        );
                        return "";
                      })() || ""}
                  onChange={(e) => {
                    dispatch(
                      setSelectedTemplate({
                        ...selectedTemplate,
                        content: {
                          ...selectedTemplate.content,
                          body: e.target.value,
                        },
                      })
                    );
                  }}
                  className="outline-none text-start w-full h-full resize-none bg-primary p-2"
                  placeholder="Type your message here..."
                />
              </div>
              <div className="w-full flex justify-between">
                <select
                  onChange={(e) => {
                    // console.log(onFocused);
                    if (!onFocused) {
                      dispatch(
                        setSelectedTemplate({
                          ...selectedTemplate,
                          content: {
                            ...selectedTemplate.content,
                            body:
                              selectedTemplate.content.body +
                              " " +
                              e.target.value,
                          },
                        })
                      );
                    } else {
                      const message = document.getElementById("message") as any;
                      if (message) {
                        const start = message.selectionStart;
                        const end = message.selectionEnd;
                        const text = message.value;
                        const before = text.substring(0, start);
                        const after = text.substring(end, text.length);
                        const selectedText = text.substring(start, end);
                        message.value = before + e.target.value + after;
                        message.selectionStart = start + e.target.value.length;
                        message.selectionEnd = start + e.target.value.length;
                        dispatch(
                          setSelectedTemplate({
                            ...selectedTemplate,
                            content: {
                              ...selectedTemplate.content,
                              body:
                                before + e.target.value + after + selectedText,
                            },
                          })
                        );
                      }
                    }
                    e.target.selectedIndex = 0;
                  }}
                  name=""
                  id=""
                  className="p-2 border-2 border-mainColor/20  outline-none rounded-md"
                >
                  <option value="Insert" disabled selected>
                    Insert a variable
                  </option>
                  <option value="{{firstName}}">{`{{firstname}}`}</option>
                  <option value="{{lastName}}">{`{{lastname}}`}</option>

                  <option value="{{company}}">{`{{company}}`}</option>
                  <option value="{{position}}">{`{{position}}`}</option>
                  <option value="{{location}}">{`{{location}}`}</option>
                </select>
                <div className="flex gap-2">
                  <button
                    disabled={loading}
                    onClick={handleTemplateSave}
                    className=" border-2 border-mainColor  p-2 px-4 rounded-md font-semibold text-mainColor"
                  >
                    Save Template
                  </button>
                  <button
                    onClick={() => {
                      setShowNoteInput(false);
                      if(props.idx < actionsAdded.length){
                        const updatedActions = [...newCampaignData.actions];
                        updatedActions[props.idx] = {
                          ...updatedActions[props.idx],
                          customMessage: selectedTemplate.content.body,
                        };
                        dispatch(
                          setNewCampaignData({
                            ...newCampaignData,
                            actions: updatedActions,
                          })
                        );
                      }else{
                        dispatch(
                          setActionsAdded([...actionsAdded, "Invitation"])
                        );
                        dispatch(
                          setNewCampaignData({
                            ...newCampaignData,
                            actions: [
                              ...newCampaignData.actions,
                              {
                                type: "invitation",
                                customMessage: selectedTemplate.content.body,
                              },
                            ],
                          })
                        );
                      }


                      props.onClose();
                    }}
                    className="bg-mainColor text-white p-2 px-4 rounded-md font-semibold"
                  >
                    {props.idx < actionsAdded.length ? <>Edit</> : <>Add More</>}
                  </button>
                </div>
              </div>
            </div>
            <div className="w-[30%] gap-2 flex flex-col px-4">
              <div className="border-2 flex w-full justify-start gap-4 font-semibold items-center text-white bg-mainColor p-4 rounded-md">
                <IoMdBulb size={30} />
                <span>Message Preview</span>
              </div>
              <div className="bg-primary w-full  border-mainColor/50 border-2 rounded-lg p-4 flex flex-col">
                <div className="flex w-full flex-col gap-4 items-start">
                  <div className="flex justify-center items-center gap-2">
                    <span className="p-1 text-mainColor bg-mainColor/20 rounded-lg">
                      <MdPerson size={30} />
                    </span>
                    <h1 className="font-semibold">John Doe</h1>
                  </div>
                  <div className="bg-white w-full h-full p-2 rounded-md text-sm">
                    <textarea
                      disabled
                      value={
                        selectedTemplate?.content.body
                        .replace(/{{company}}/g, "Leadseeder")
                        .replace(/{{position}}/g, "CEO")
                        .replace(/{{location}}/g, "San Francisco, CA")
                        .replace(/{{firstName}}/g, "John")
                        .replace(/{{lastName}}/g, "Doe") || ""
                      }
                      placeholder="Subject"
                      className="outline-none resize-none bg-white  w-full h-32"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-4 flex w-fit mx-auto flex-col justify-center items-start">
            <h1 className="text-xl font-semibold">
              Would you like to add an Invitation note?
            </h1>
            <small>
              If you are not a Linkedin premium user, your invitation note can
              be only sent to 5 prospects
            </small>
            <div className="flex w-full gap-4 mt-4">
              <button
                onClick={() => {
                  setShowNoteInput(true);
                }}
                className="bg-mainColor text-white p-2 px-4 rounded-md font-semibold"
              >
                Yes
              </button>
              <button
                onClick={() => {
                  dispatch(setActionsAdded([...actionsAdded, "Invitation"]));
                  dispatch(
                    setNewCampaignData({
                      ...newCampaignData,
                      actions: [
                        ...newCampaignData.actions,
                        { type: "invitation", customMessage: "" },
                      ],
                    })
                  );

                  props.onClose();
                }}
                className="bg-mainColor text-white p-2 px-4 rounded-md font-semibold"
              >
                No
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddInvitationNoteModal;
