import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MdPersonAdd } from "react-icons/md";
import AddMemberModal from "./AddMemberModal";
import { FiSearch } from "react-icons/fi";
import { ownerSelector, teamsSelector } from "../../store/store";
import { fetchOwner } from "../../utils";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import { updateTeam, removeMember } from "../../store/slices/teamsSlice";
import {removeOwnerCredit, setOwner} from "../../store/slices/ownerSlice";
import { useNavigate } from "react-router-dom";
import PurchaseCreditsModal from './PurchaseCreditsModal';

type Props = {};

const TeamsMain = (props: Props) => {
  const dispatch = useDispatch();
  const [showAddmemberModal, setShowAddmemberModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const owner = useSelector(ownerSelector);
  const teams = useSelector(teamsSelector);
  const creditsLeft = owner.owner?.credits?.filter((credit) => !credit.ownerId && new Date(credit.endDate) > new Date()).length;
  const [members, setTeamsMembers] = useState<any[]>([])
  const [showOptions, setShowOptions] = useState<string | null>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const originalOwner = JSON.parse(localStorage.getItem("owner") || "");
  const [isTeamsOwner, setIsTeamsOwner] = useState<boolean>(false);
  const navigate = useNavigate();
  const [showPurchaseCreditsModal, setShowPurchaseCreditsModal] = useState(false);

  const handleRemoveMember = async (ownerId: string)=>{
    try { 
      const res = await axios.post("/teams/removeTeamMember", {
        ownerId: ownerId,
      });

      if (res.data) {
        toast.success("Member removed successfully");
        dispatch(removeMember(ownerId));
        dispatch(removeOwnerCredit(ownerId));
        dispatch(updateTeam(res.data.team))
      } else {
        toast.error(res.data.message);
      }
    } catch (error: any) {
      // toast.error(error.res);
      console.log(error);
    }
  }

  const handleAccessApp = async(owner: any)=>{
    console.log(owner);
    dispatch(setOwner(owner));
    navigate("/");
  }

  const declineInvitation = async (ownerId: string | undefined)=>{
    try { 
      const res = await axios.post("/teams/removeTeamMember", {
        ownerId: ownerId,
      });

      if (res.data) {
        toast.success("Invitation declined successfully");
        dispatch(updateTeam(null))
      } else {
        toast.error(res.data.message);
      }
    } catch (error: any) {
      // toast.error(error.res);
      console.log(error);
    }
  }

  const acceptInvitation = async (ownerId: string | undefined)=>{
    try { 
      const res = await axios.post("/teams/acceptInvitation", {
        ownerId: ownerId,
      });

      if (res.data) {
        toast.success("Invitation declined successfully");
        dispatch(updateTeam(res.data.team))
      } else {
        toast.error(res.data.message);
      }
    } catch (error: any) {
      // toast.error(error.res);
      console.log(error);
    }
  }

  // Close options when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target as Node)) {
        setShowOptions(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Check if current user has pending status in the team
  const isPendingMember = teams.team?.members?.find(member => 
    member.ownerId === owner.owner?._id && member.status === 'PENDING'
  );

  const fetchOwners = async () => {
    console.log(teams);
    try {
      const members = teams.team?.members || []; // Ensure members is an array
      const ownerPromises = members.map(async (member) => {
        const ownerId = member.ownerId; // Assuming each team has an ownerId
        const ownerData = await fetchOwner(ownerId); // Fetch owner data using the utility function
        return ownerData;
      });
      
      if (ownerPromises.length > 0) { // Check if there are promises to resolve
        const owners = await Promise.all(ownerPromises);
        setTeamsMembers(owners);
        // You can now set the owners state or do something with the ownersData
      } else {
        console.log("No team members found.");
      }
    } catch (error) {
      console.error("Error fetching owners:", error);
    }
  };

  useEffect(() => {
    fetchOwners();
    teams.team?.members?.find((member) => member.ownerId === originalOwner._id && member.role === "ADMIN") ? setIsTeamsOwner(true) : setIsTeamsOwner(false)
  }, [teams]);
  // Example team members data
  const teamMembers: any[] = members;

  // Filter team members based on search query
  const filteredMembers = teamMembers.filter(member =>
    member.name.toLowerCase().includes(searchQuery.toLowerCase()) || 
    member.email.toLowerCase().includes(searchQuery.toLowerCase())
  );
  if (!teams.team) {
    return (
      <section className="bg-white rounded-md h-screen p-6">
        {showPurchaseCreditsModal && (
          <PurchaseCreditsModal 
            isOpen={showPurchaseCreditsModal} 
            onRequestClose={() => setShowPurchaseCreditsModal(false)} 
          />
        )}
        <div className="max-w-4xl mx-auto py-16">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">You don't have a Team 👀</h1>
            <p className="text-xl text-gray-600 mb-8">Add members to your Team ➡️ & exceed your objectives!</p>
            <button
              onClick={() => setShowPurchaseCreditsModal(true)}
              className="bg-mainColor text-white px-8 py-3 rounded-lg hover:bg-mainColor/90 transition-all"
            >
              Create Team
            </button>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="p-6 border border-gray-100 rounded-lg">
              <h3 className="font-semibold mb-2">Collaborate & Share</h3>
              <p className="text-sm text-gray-500">Choose plan and seats for you and your team.</p>
            </div>
            <div className="p-6 border border-gray-100 rounded-lg">
              <h3 className="font-semibold mb-2">Centralized account management</h3>
              <p className="text-sm text-gray-500">All your data in one place</p>
            </div>
            <div className="p-6 border border-gray-100 rounded-lg">
              <h3 className="font-semibold mb-2">Cross-account lead import</h3>
              <p className="text-sm text-gray-500">Share prospects easily</p>
            </div>
            <div className="p-6 border border-gray-100 rounded-lg">
              <h3 className="font-semibold mb-2">Anti-Duplication Security</h3>
              <p className="text-sm text-gray-500">This person looks familiar...</p>
            </div>
            <div className="p-6 border border-gray-100 rounded-lg">
              <h3 className="font-semibold mb-2">Access to other Team accounts</h3>
              <p className="text-sm text-gray-500">To help your teammate if they struggle</p>
            </div>
            <div className="p-6 border border-gray-100 rounded-lg">
              <h3 className="font-semibold mb-2">Upgrade and Create your Team</h3>
              <p className="text-sm text-gray-500">Get started with team collaboration</p>
            </div>
          </div>
        </div>
      </section>
    );
  }

  // Show pending invitation page if user has pending status
  if (isPendingMember) {
    return (
      <section className="bg-white rounded-md h-screen p-6">
        <div className="max-w-2xl mx-auto py-24 text-center">
          <div className="bg-gradient-to-r from-mainColor/10 to-mainColor/5 p-8 rounded-3xl border border-mainColor/20">
            <div className="mx-auto w-24 h-24 bg-mainColor/10 rounded-full flex items-center justify-center mb-6">
              <svg 
                className="w-12 h-12 text-mainColor" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={1.5} 
                  d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" 
                />
              </svg>
            </div>
            <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-mainColor to-mainColor/80 bg-clip-text text-transparent">
              Team Invitation 🎉
            </h1>
            <p className="text-xl text-gray-600 mb-8">
              You've been invited to join <span className="font-semibold text-mainColor">{teams.team?.name}</span>
            </p>
            <div className="flex justify-center gap-4">
              <button
                onClick={() => acceptInvitation(owner.owner?._id)}
                className="bg-mainColor text-white px-8 py-3 rounded-lg hover:bg-mainColor/90 transition-all flex items-center gap-2 shadow-md hover:shadow-lg"
              >
                <svg 
                  className="w-5 h-5" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M5 13l4 4L19 7" 
                  />
                </svg>
                Accept Invitation
              </button>
              <button
                onClick={() => declineInvitation(owner.owner?._id)}
                className="bg-white text-gray-700 px-8 py-3 rounded-lg hover:bg-gray-50 transition-all flex items-center gap-2 border border-gray-200 shadow-sm hover:shadow-md"
              >
                <svg 
                  className="w-5 h-5" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M6 18L18 6M6 6l12 12" 
                  />
                </svg>
                Decline
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  }


  

  return (
    <section className="bg-white rounded-md h-screen p-6">
      <div className="flex justify-between items-center mb-8">
        <div>
          <div className="flex items-center gap-4">
            <h1 className="text-2xl font-semibold">{teams.team?.name}</h1>
            <div className="relative">
              {teams.team?.members.find(m => m.ownerId === originalOwner?._id)?.role !== "ADMIN" && <button 
                onClick={() => setShowOptions('team-options')}
                className="p-1 hover:bg-gray-100 rounded-full"
              >
                <svg className="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                </svg>
              </button>}
              {showOptions === 'team-options' && (
                <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  <div className="py-1">
                    <button
                      onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveMember(owner.owner?._id || "");
                        window.location.reload();
                      }}
                      className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-left"
                    >
                      Leave Team
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <p className="text-sm text-gray-500">Manage your team members and credits</p>
        </div>
        <div className="flex items-center gap-6">
          <div className="bg-gray-50 p-3 rounded-lg">
            <p className="text-sm text-gray-600">Available Credits</p>
            <p className="text-xl font-semibold">{creditsLeft}</p>
          </div>
          <button
            onClick={() => {
              const teamMember = teams.team?.members.find(m => m.ownerId === owner.owner?._id);
              if(teamMember?.role === "ADMIN" && creditsLeft){
                setShowAddmemberModal(true)
              }else{
                setShowPurchaseCreditsModal(true);
              }
            }}
            className="text-white bg-mainColor hover:bg-mainColor/90 transition-all flex rounded-lg px-4 py-2 justify-center items-center gap-2"
          >
            <MdPersonAdd size={20} />
            <p className="text-sm">Add Member</p>
          </button>
          <button
            onClick={() => setShowPurchaseCreditsModal(true)}
            className="text-white bg-mainColor hover:bg-mainColor/90 transition-all flex rounded-lg px-4 py-2 justify-center items-center gap-2"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <p className="text-sm">Purchase Credits</p>
          </button>
        </div>
      </div>

      {/* Add this banner section */}
      {creditsLeft === 0 && (
        <div className="mb-6 bg-red-50 border border-red-200 rounded-lg p-4 flex justify-between items-center">
          <div>
            <h3 className="text-red-800 font-medium mb-1">No credits remaining</h3>
            <p className="text-red-700 text-sm">Add more credits to invite team members</p>
          </div>
          <button
            onClick={() => setShowPurchaseCreditsModal(true)}
            className="bg-mainColor hover:bg-mainColor/90 text-white px-4 py-2 rounded-lg text-sm transition-all"
          >
            Purchase Credits
          </button>
        </div>
      )}

      {/* Search Bar */}
      <div className="mb-8">
        <div className="relative">
          <input
            type="text"
            placeholder="Search team members..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:border-mainColor transition-all"
          />
          <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {teamMembers.length === 0 ? (
          <div className="col-span-full text-center py-16 space-y-6">
            <div className="mx-auto max-w-lg flex flex-col items-center">
              <svg 
                className="w-24 h-24 text-gray-300 mb-6" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={1.5} 
                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" 
                />
              </svg>
              <h2 className="text-2xl font-semibold text-gray-800 mb-3 flex items-center gap-2">
                Grow Your Team Power
                <svg 
                  className="w-5 h-5 text-mainColor mt-1" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M14 5l7 7m0 0l-7 7m7-7H3" 
                  />
                </svg>
              </h2>
              <div 
                className="border-2 border-dashed border-gray-200 rounded-xl p-6 w-full 
                         hover:border-mainColor/50 transition-all cursor-pointer
                         hover:scale-[1.01] hover:shadow-sm"
                onClick={() => setShowAddmemberModal(true)}
              >
                <div className="flex flex-col items-center gap-3">
                  <div className="w-10 h-10 bg-mainColor/10 rounded-full flex items-center justify-center">
                    <MdPersonAdd className="text-mainColor w-5 h-5" />
                  </div>
                  <p className="text-sm text-gray-600 font-medium">
                    Get started by inviting your first team member
                  </p>
                  <p className="text-xs text-gray-400 mt-1">
                    Team members will appear here once added
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          filteredMembers.map(member => {
            const teamMember = teams.team?.members.find(m => m.ownerId === member._id);
            return (
              <div key={member._id} className="bg-gray-50 p-4 rounded-lg hover:shadow-md transition-shadow">
                <div className="flex items-center gap-4">
                  <img src={member.picture} alt={member.name} className="w-12 h-12 rounded-full" />
                  <div>
                    <p className="font-medium">{member.name}</p>
                    <p className="text-sm text-gray-500">{member.email}</p>
                  </div>
                </div>
                <div className="mt-4 flex justify-between items-center">
                  <div className="flex gap-2">
                    <span className="text-sm px-3 py-1 bg-gray-200 rounded-full">
                      {teamMember?.role}
                    </span>
                    {teamMember?.status === 'PENDING' && (
                      <span className="text-sm px-3 py-1 bg-yellow-100 text-yellow-800 rounded-full">
                        Pending
                      </span>
                    )}
                  </div>
                  <div className="relative" ref={optionsRef}>
                    {isTeamsOwner && <button 
                      onClick={() => setShowOptions(prev => prev === member._id ? null : member._id)}
                      className="p-1 hover:bg-gray-100 rounded-full"
                    >
                      <svg className="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                      </svg>
                    </button>}
                    {showOptions === member._id && (
                      <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                        <div className="py-1">
                          {teamMember?.status === "ACTIVE" && <button
                            onMouseDown={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleAccessApp(member);
                            }}
                            className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            Access App
                          </button>}
                          {teamMember?.role !== "ADMIN" && member._id !== owner.owner?._id && <button
                            onMouseDown={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveMember(member._id);
                            }}
                            className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            Remove Member
                          </button>}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>

      {showAddmemberModal && (
        <AddMemberModal onClose={() => setShowAddmemberModal(false)} />
      )}

      {showPurchaseCreditsModal && (
        <PurchaseCreditsModal 
          isOpen={showPurchaseCreditsModal} 
          onRequestClose={() => setShowPurchaseCreditsModal(false)} 
        />
      )}
    </section>
  );
};

export default TeamsMain;
