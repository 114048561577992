import React from "react";
import { FaCrown } from "react-icons/fa6";
import { MdInfoOutline, MdQueue, MdRocketLaunch } from "react-icons/md";
import { useSelector } from "react-redux";
import { dashboardSelector, ownerSelector } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { Popover } from "antd";

type Props = {};

const ProspectingStatusCard = (props: Props) => {
  const { campaigns } = useSelector(ownerSelector);
  const { campaignsData } = useSelector(dashboardSelector);
  const navigate = useNavigate();

  return (
    <div className="bg-white  w-1/3 flex flex-col justify-center items-center p-4 shadow-lg  rounded-md relative ">
      <div className="absolute right-2 top-3"></div>
      <div className="flex w-full justify-between">
        <h2 className=" text-sm font-semibold">Prospecting Status</h2>

        {campaigns.filter(
          (campaign: any) => campaign.campaignStatus === "ACTIVE"
        ).length > 0 ? (
          <p className="p-1 bg-green-400/40 text-green-800 rounded-lg text-xs flex gap-1 justify-center">
            Active
            <Popover
              placement="top"
              content={<p className="text-xs">You have an active campaign</p>}
            >
              <MdInfoOutline size={15} />
            </Popover>
          </p>
        ) : (
          <p className="p-1 bg-red-400/40 text-red-800 rounded-lg text-xs flex gap-1 justify-center">
            Inactive
            <Popover
              placement="top"
              content={<p className="text-xs">You have no active campaign</p>}
            >
              <MdInfoOutline size={15} />
            </Popover>
          </p>
        )}
      </div>
      <div className="w-full flex">
        <div
          className="w-1/2 flex flex-col justify-center gap-2 items-center h-28 cursor-pointer"
          onClick={() => navigate("/campaigns/list/running")}
        >
          <span className="flex justify-center items-center gap-2 ">
            <p className="text-mainColor p-2 bg-primary rounded-full ">
              <MdRocketLaunch size={20} />
            </p>
            <p className="">
              {campaigns.filter(
                (campaign: any) => campaign.campaignStatus === "ACTIVE"
              ).length || 0}
            </p>
          </span>
          <p className=" text-xs">Active Campaigns</p>
        </div>
        <div className="w-1/2 flex flex-col gap-2 justify-center items-center h-28">
          <span className="flex justify-center items-center gap-2">
            <p className="text-mainColor text-5xl bg-primary rounded-full p-2">
              <MdQueue size={20} />
            </p>
            <p className="">{campaignsData.pendingActions || 0}</p>
          </span>
          <p className=" text-xs">Queued Actions</p>
        </div>
      </div>
      <button
        onClick={() => navigate("/campaigns/create")}
        className="bg-mainColor gap-4 flex justify-center items-center text-white p-2 rounded-lg w-full mt-4 text-xs font-semibold"
      >
        <MdRocketLaunch size={20} className="inline-block" />
        Create Campaign
      </button>
    </div>
  );
};

export default ProspectingStatusCard;
