import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { ownerSelector } from "../../store/store";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import { setCurrentWorkSpace, setOwner } from "../../store/slices/ownerSlice";
import { updateTeam } from "../../store/slices/teamsSlice";

type Props = {
  onClose: () => void;
};

const AddMemberModal = (props: Props) => {
  const { owner, currentWorkspace } = useSelector(ownerSelector);
  // console.log(owner);
  // console.log(currentWorkSpace);
  const [newMemberData, setNewMemberData] = useState({
    email: "",
    role: "",
  });
  const dispatch = useDispatch();

  const handleMemberAdd = async () => {
    try { 

      const res = await axios.post("/teams/addTeamMember", {
        linkedinurl: newMemberData.email,
        teamId: owner?.teamId,
        role: "EDITOR",
      });

      if (res.data) {
        toast.success("Member added successfully");
        dispatch(updateTeam(res.data.team))
        dispatch(setOwner(res.data.owner))
        props.onClose();
      } else {
        toast.error(res.data.message);
      }
    } catch (error: any) {
      // toast.error(error.res);
      console.log(error);
    }
  };
  return (
    <div>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <div className="bg-white p-6 rounded-lg w-[500px] shadow-lg">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold text-gray-800">Add Team Member</h1>
            <div className="p-1 hover:bg-gray-100 rounded-full transition-colors">
              <MdClose
                onClick={props.onClose}
                size={24}
                className="text-gray-600 cursor-pointer"
              />
            </div>
          </div>

          <div className="space-y-6">
            <div className="flex flex-col gap-2">
              <label htmlFor="linkedinurl" className="text-sm font-medium text-gray-700">
                LinkedIn Profile URL
              </label>
              <input
                onChange={(e) => setNewMemberData({ ...newMemberData, email: e.target.value })}
                type="url"
                name="linkedinurl"
                id="linkedinurl"
                placeholder="https://linkedin.com/in/username"
                className="p-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
              />
            </div>

            <button
              onClick={handleMemberAdd}
              className="w-full bg-green-500 hover:bg-green-600 text-white font-medium py-2.5 px-4 rounded-lg transition-colors focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              Add Team Member
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMemberModal;
