import React, { useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md"; // Delete icon
import { FaRegEnvelope, FaUserCheck, FaClock, FaEye } from "react-icons/fa"; // Icons for actions
import { FiArrowRight } from "react-icons/fi";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { ownerSelector, prospectsSelector, templateSelector } from "../../store/store";
import AddMessageModal from "./AddMessageModal";
import {
  setActionsAdded,
  setNewCampaignData,
} from "../../store/slices/ownerSlice";
import AddDelayModal from "./AddDelayModal";
import { setCurrentStep } from "../../store/slices/campaignSlice";
import AddInvitationNoteModal from "./AddInvitationNoteModal";
import {
  setSelectedTemplate,
  setTemplates,
} from "../../store/slices/templateSlice";
import { Modal } from "../common/Modal"; // Assuming you have a Modal component

type Props = {};

const AddActions = (props: Props) => {
  const { actionsAdded, newCampaignData } = useSelector(ownerSelector);
  const [action, setAction] = useState(
    actionsAdded.length === 0 ? "Invitation" : "Visit"
  );
  const { connectedProspects } = useSelector(prospectsSelector);
  const [showDelay, setShowDelay] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [editActionIdx, setEditActionIdx] = useState(0);
  const { selectedTemplate, templates, loading } =
    useSelector(templateSelector);
  const dispatch = useDispatch();
  const [previewContent, setPreviewContent] = useState<{ title: string; content: string } | null>(null);

  const handleEditAction = (idx: number) => {
    setEditActionIdx(idx);
    if(actionsAdded[idx] === "Invitation") {
      setShowNoteModal(true)
    }else if(actionsAdded[idx] === "Delay"){
      setShowDelay(true);
    }else if(actionsAdded[idx] === "Message"){
      setShowMessageModal(true);
    }
  }

  const handleAddAction = () => {
    // console.log("Adding action", action);
    setEditActionIdx(actionsAdded.length);
    if (action === "Message" && actionsAdded.length === 0) {
      if (connectedProspects.length === 0) {
        toast.error("No connected prospects");
        return;
      } else {
        dispatch(
          setNewCampaignData({
            ...newCampaignData,
            prospects: connectedProspects,
          })
        );

        setShowMessageModal(true);
      }
    } else if (action === "Message") {
      setShowMessageModal(true);
      return;
    }

    if (action === "Invitation") {
      setShowNoteModal(true);
      // dispatch(
      //   setNewCampaignData({
      //     ...newCampaignData,
      //     actions: [...newCampaignData.actions, { type: "invitation" }],
      //   })
      // );
      // dispatch(setActionsAdded([...actionsAdded, action]));
      setAction("Visit");
    }

    if (action === "Delay") {
      setShowDelay(true);
      //   if (actionsAdded[actionsAdded.length - 1] === "Delay") {
      //     toast.error("You can't add delay after delay");
      //     return;
      //   }

      //   if (actionsAdded.length === 0) {
      //     toast.error("Please add other actions first");
      //     return;
      //   }
      //   dispatch(setActionsAdded([...actionsAdded, "Delay"]));
      //   setAddingDelay(true);
      setAction("Visit");
    } else if (action === "Message") {
      if (!actionsAdded.includes("Invitation")) {
        if (connectedProspects.length === 0) {
          toast.error("No connected prospects found! Add invitation first");
          return;
        } else {
          dispatch(
            setNewCampaignData({
              ...newCampaignData,
              prospects: connectedProspects,
            })
          );
        }
      } else {
        dispatch(setActionsAdded([...actionsAdded, "Message"]));
        setAction("Visit");
      }
    } else if (action === "Visit") {
      if (actionsAdded[actionsAdded.length - 1] === "Delay") {
        // if (!actionsAdded.includes("Invitation")) {
        //   toast.error("Please add invitation first");
        //   return;
        // }
        dispatch(setActionsAdded([...actionsAdded, action]));
        dispatch(
          setNewCampaignData({
            ...newCampaignData,
            actions: [
              ...newCampaignData.actions,
              {
                type: "visitWithDelay",
                delay:
                  newCampaignData.actions[newCampaignData.actions.length - 1]
                    .delay,
              },
            ],
          })
        );
      } else {
        dispatch(setActionsAdded([...actionsAdded, action]));
        dispatch(
          setNewCampaignData({
            ...newCampaignData,
            actions: [...newCampaignData.actions, { type: "visit" }],
          })
        );
      }
    }
  };

  // Function to remove action from the list
  const handleRemoveAction = (actionIndex: number) => {
    try {
      console.log("Removing action", actionsAdded);
      if (actionsAdded.length === 1) {
        dispatch(setActionsAdded([]));
        dispatch(setNewCampaignData({ ...newCampaignData, actions: [] }));
        return;
      }
      if (actionsAdded[actionIndex] === "Delay") {
        console.log(actionIndex);
        const deleteCount = actionsAdded.length - actionIndex;
        console.log(deleteCount);
        const newActions = actionsAdded.slice(0, actionIndex);
        console.log(newActions);
        dispatch(setActionsAdded(newActions));
        toast.warning("Removing delay removes all actions after it");

        // remove any message with delay or visit with delay
        dispatch(
          setNewCampaignData({
            ...newCampaignData,
            actions: newCampaignData.actions.filter(
              (action: any) =>
                action.type !== "messageWithDelay" &&
                action.type !== "visitWithDelay"
            ),
          })
        );
        return;
      }

      if (actionsAdded[actionIndex] === "Invitation") {
        if (connectedProspects.length === 0) {
          toast.error("No connected prospects for other actions!");
          return;
        } else {
          dispatch(
            setNewCampaignData({
              ...newCampaignData,
              prospects: connectedProspects,
            })
          );
        }
      }

      if(actionIndex == 0 || actionsAdded[actionIndex-1] !== "Delay"){
        dispatch(
          setNewCampaignData({
            ...newCampaignData,
            actions: newCampaignData.actions.filter(
              (a, idx) => idx !== actionIndex
            ),
          })
        );
        dispatch(
          setActionsAdded(actionsAdded.filter((a, idx) => idx !== actionIndex))
        );
      }else{
        console.log("here");
        dispatch(
          setNewCampaignData({
            ...newCampaignData,
            actions: newCampaignData.actions.filter(
              (a, idx) => idx !== actionIndex && idx !== actionIndex - 1
            ),
          })
        );
        dispatch(
          setActionsAdded(actionsAdded.filter((a, idx) => idx !== actionIndex && idx !== actionIndex - 1))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleActionsFinalize = () => {
    if(actionsAdded.length == 0){
      toast.error("Add at least one action before proceeding to the next step.");
      return;
    }
    if(actionsAdded[actionsAdded.length - 1] === "Delay"){
      toast.error("The last action in the sequence cannot be a delay. Please choose a different action.");
      return;
    }
    dispatch(setCurrentStep({ idx: 3, key: "reviewLaunch" }));
  };

  // Action icons based on the action type
  const getActionIcon = (actionType: string) => {
    switch (actionType) {
      case "Invitation":
        return <FaUserCheck size={18} />;
      case "Visit":
        return <FaEye size={18} />;
      case "Message":
        return <FaRegEnvelope size={18} />;
      case "Delay":
        return <FaClock size={18} />;
      default:
        return null;
    }
  };

  const handlePreview = (actionType: string, idx: number) => {
    if (actionType === "Invitation") {
      setPreviewContent({
        title: "Invitation Note",
        content: newCampaignData.actions[idx].customMessage
      });
    } else if (actionType === "Message") {
      setPreviewContent({
        title: "Message Preview",
        content: newCampaignData.actions[idx].message.body
      });
    } else if (actionType === "Delay") {
      setPreviewContent({
        title: "Delay Details",
        content: `Delay duration: ${newCampaignData.actions[idx].delay} days`
      });
    }
  };

  return (
    <div className="p-6 flex w-full flex-col justify-center items-center bg-gradient-to-br from-gray-50 to-gray-100 min-h-screen">
      <div className="bg-white rounded-2xl shadow-xl p-8 w-full max-w-7xl border border-gray-100 h-[80vh]">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Campaign Action Sequence</h1>
        {action === "Message" && actionsAdded.length === 0 && (
          <div className="bg-blue-50/80 text-blue-900 px-4 py-3 rounded-lg mb-6 text-sm border border-blue-100">
            Note: Only connected prospects will be added to message actions
          </div>
        )}
        
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Action Control Panel */}
          <div className="w-full lg:w-1/3 h-[80vh]">
            <div className="bg-gradient-to-b from-gray-50 to-white p-6 rounded-2xl border border-gray-100 shadow-sm ">
              <h2 className="text-lg font-semibold text-gray-800 mb-4">Add New Action</h2>
              <div className="space-y-6">
                <select
                  value={action}
                  onChange={(e) => setAction(e.target.value)}
                  className="w-full p-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all bg-white/80 backdrop-blur-sm"
                >
                  {!actionsAdded.includes("Invitation") &&
                    actionsAdded[0] !== "Message" && (
                      <option value="Invitation">Send Invitation</option>
                    )}
                  <option value="Visit">Profile Visit</option>
                  <option value="Message">Send Message</option>
                  <option value="Delay">Add Delay</option>
                </select>
                
                <div className="flex flex-col gap-4">
                  <button
                    onClick={handleAddAction}
                    className="text-white bg-mainColor hover:bg-mainColor/90 transition-all flex rounded-lg px-4 py-2 justify-center items-center gap-2"
                  >
                    <span>Add {action}</span>
                    {action === "Visit" && <FaEye className="w-5 h-5" />}
                    {action === "Message" && <FaRegEnvelope className="w-5 h-5" />}
                    {action === "Delay" && <FaClock className="w-5 h-5" />}
                    {action === "Invitation" && <FaUserCheck className="w-5 h-5" />}
                  </button>
                  
                  <button
                    onClick={handleActionsFinalize}
                    className="text-white bg-mainColor hover:bg-mainColor/90 transition-all flex rounded-lg px-4 py-2 justify-center items-center gap-2"
                  >
                    <span>Next</span>
                    <FiArrowRight className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Action Sequence Flow */}
          <div className="w-full lg:w-2/3">
            <div className="bg-gradient-to-b from-gray-50 to-white p-6 rounded-2xl border border-gray-100 shadow-sm h-[60vh]">
              <h2 className="text-lg font-semibold text-gray-800 mb-4">Action Sequence</h2>
              <div className="overflow-y-auto h-[500px] pr-4">
                {actionsAdded.length === 0 ? (
                  <div className="text-center py-8">
                    <p className="text-gray-600">No actions added yet</p>
                    <p className="text-sm text-gray-500 mt-2">Start building your campaign sequence</p>
                  </div>
                ) : (
                  <div className="space-y-4">
                    {actionsAdded.map((addedAction, idx) => (
                      <React.Fragment key={idx}>
                        <div className="flex items-center justify-between p-4 bg-white rounded-xl shadow-sm border border-gray-100 hover:shadow-md transition-all h-[80px] backdrop-blur-sm bg-white/80 relative z-1">
                          <div className="flex items-center gap-3">
                            <div className="p-2 bg-gradient-to-br from-blue-50 to-blue-100 rounded-lg">
                              {getActionIcon(addedAction)}
                            </div>
                            <div>
                              <p className="font-medium text-gray-900 capitalize">{addedAction}</p>
                              <p className="text-sm text-gray-600">Step {idx + 1}</p>
                            </div>
                          </div>
                          <div className="flex gap-3">
                            {(addedAction === "Invitation" || addedAction === "Message" || addedAction === "Delay") && (
                              <button
                                onClick={() => handlePreview(addedAction, idx)}
                                className="text-green-600 hover:text-green-700 transition-colors px-3 py-1 rounded-md bg-green-100 hover:bg-green-200"
                              >
                                Preview
                              </button>
                            )}
                            {addedAction !== "Visit" && (
                              <button
                                onClick={() => handleEditAction(idx)}
                                className="btn-icon hover:bg-blue-50/50 transition-all"
                                title="Edit action"
                              >
                                <MdEdit size={18} className="text-blue-600" />
                                <span className="sr-only">Edit action</span>
                              </button>
                            )}
                            <button
                              onClick={() => handleRemoveAction(idx)}
                              className="btn-icon btn-icon-danger hover:bg-red-50/50 transition-all"
                              title="Remove action"
                            >
                              <MdDelete size={18} className="text-red-600" />
                              <span className="sr-only">Remove action</span>
                            </button>
                          </div>
                        </div>
                        {idx < actionsAdded.length - 1 && (
                          <div className="flex justify-center">
                            <div className="w-0.5 h-8 bg-gradient-to-b from-gray-200 to-transparent"></div>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showMessageModal && (
        <AddMessageModal idx={editActionIdx} onClose={() => setShowMessageModal(false)} />
      )}

      {showDelay && <AddDelayModal idx={editActionIdx} onClose={() => setShowDelay(false)} />}

      {showNoteModal && (
        <AddInvitationNoteModal idx={editActionIdx} onClose={() => setShowNoteModal(false)} />
      )}

      {/* Preview Modal */}
      {previewContent && (
        <Modal
          isOpen={!!previewContent}
          onClose={() => setPreviewContent(null)}
          title={previewContent.title}
        >
          <div className="p-4">
            <div className="whitespace-pre-wrap break-words bg-gray-50 p-4 rounded-lg">
              {previewContent.content}
            </div>
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => setPreviewContent(null)}
                className="text-white bg-mainColor hover:bg-mainColor/90 transition-all flex rounded-lg px-4 py-2 justify-center items-center gap-2"
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AddActions;
