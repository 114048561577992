import { createSlice } from "@reduxjs/toolkit";
import {
  addTagToProspect,
  createCampaign,
  createList,
  createTag,
  deleteList,
  launchCampaign,
  login,
  removeTagFromProspect,
  saveCampaign,
  updateCampaign,
  updateDailyQuota,
  updateList,
  updateOwner,
} from "../features/ownerActions";
import { List, Owner, Tag, Workspace } from "../../types";

export type OwnerState = {
  owner: Owner | null;
  currentWorkspace: any;
  lists: List[];
  tags: Tag[];
  allProspectsList: List;
  listId: string;
  loading: boolean;
  error: string;
  success: boolean;
  newCampaignData: {
    ownerId: string;
    actions: any[];
    campaignStatus: string;
    campaignName: string;
    campaignId: string;
    listId: string;
    prospects: any[];
  };
  campaigns: any[];
  actionsAdded: string[];
  campaignId: string;
};

const initialState: OwnerState = {
  owner: localStorage.getItem("owner")
    ? JSON.parse(localStorage.getItem("owner")!)
    : null,
  currentWorkspace: localStorage.getItem("currentWorkSpace") || null,
  lists: [],
  tags: [],
  allProspectsList: {
    _id: "all",
    listName: "All Prospects",
    description: "All prospects in the workspace",
    prospects: [],
    ownerId: "",
    isDeleted: false,
    inActiveCampaign: false,
  },
  listId: "",
  loading: false,
  error: "",
  success: false,
  newCampaignData: {
    ownerId: localStorage.getItem("owner")
      ? JSON.parse(localStorage.getItem("owner")!)._id
      : "",
    actions: [],
    campaignStatus: "ACTIVE",
    campaignName: "Invitation",
    campaignId: "",
    listId: "",
    prospects: [],
  },
  campaigns: [],
  actionsAdded: [],
  campaignId: "",
};

const ownerSlice = createSlice({
  name: "owner",
  initialState: initialState,
  reducers: {
    setOwner: (state, action) => {
      state.owner = action.payload;
      // localStorage.setItem("owner", JSON.stringify(action.payload));
    },
    setCurrentWorkSpace: (state, action) => {
      state.currentWorkspace = action.payload;
      localStorage.setItem("currentWorkSpace", JSON.stringify(action.payload));
    },
    setLists: (state, action) => {
      state.lists = action.payload.filter(
        (list: any) => list.isDeleted === false
      );
    },
    setAllProspectsList: (state, action) => {
      state.allProspectsList = action.payload;
    },
    logOut: (state) => {
      state.owner = null;
      localStorage.removeItem("owner");
      localStorage.removeItem("currentWorkSpace");
      window.location.href = "/login";
    },
    setNewCampaignData: (state, action) => {
      state.newCampaignData = action.payload;
    },
    setCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },
    setTags: (state, action) => {
      state.tags = action.payload;
    },
    setCampaignId: (state, action) => {
      state.campaignId = action.payload;
    },
    setListId: (state, action) => {
      state.listId = action.payload;
    },
    setActionsAdded: (state, action) => {
      state.actionsAdded = action.payload;
    },
    removeOwnerCredit: (state, action) => {
      if (state.owner?.credits) {
        for (let credit of state.owner.credits) {
          if (credit.ownerId && credit.ownerId === action.payload) {
            credit.ownerId = undefined;
          }
        }
      }
    }
  },
  extraReducers(builder) {
    // ❗❗ Login ❗❗
    builder.addCase(login.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(login.rejected, (state, action) => {
      // @ts-ignore
      state.error = action.payload;
      state.loading = false;
    });

    builder.addCase(login.fulfilled, (state, action) => {
      state.owner = action.payload.data;
      state.loading = false;
      state.success = true;
      localStorage.setItem("owner", JSON.stringify(action.payload.data));
    });

    // ❗Add list❗
    builder.addCase(createList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createList.rejected, (state, action) => {
      // @ts-ignore
      state.error = action.payload;
      state.loading = false;
    });

    builder.addCase(createList.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
    });

    // ❗Update List❗
    builder.addCase(updateList.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateList.rejected, (state, action) => {
      // @ts-ignore
      state.error = action.payload;
      state.loading = false;
    });

    builder.addCase(updateList.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
    });

    // ❗Delete List❗

    builder
      .addCase(deleteList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteList.rejected, (state, action) => {
        // @ts-ignore
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(deleteList.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.lists = state.lists.filter(
          (list) => list._id !== action.payload._id
        );
      });

    // ❗Create campaign❗

    builder.addCase(createCampaign.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createCampaign.rejected, (state, action) => {
      // @ts-ignore
      state.error = action.payload;
      state.loading = false;
    });

    builder.addCase(createCampaign.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(saveCampaign.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(saveCampaign.rejected, (state, action) => {
      // @ts-ignore
      state.error = action.payload;
      state.loading = false;
    });

    builder.addCase(saveCampaign.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
    });
    builder
      .addCase(updateOwner.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateOwner.rejected, (state, action) => {
        // @ts-ignore
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(updateOwner.fulfilled, (state, action) => {
        // console.log(action.payload);
        state.owner = action.payload.data;
        state.loading = false;
        state.success = true;
      });

    // ❗Launch campaign❗ (Will be moved to campaign slice later)
    builder
      .addCase(launchCampaign.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(launchCampaign.rejected, (state, action) => {
        // @ts-ignore
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(launchCampaign.fulfilled, (state, action) => {
        // console.log(action.payload);
        state.loading = false;
        state.success = true;
      });

    // ❗Update campaign❗ (Will be moved to campaign slice later)
    builder
      .addCase(updateCampaign.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateCampaign.rejected, (state, action) => {
        // @ts-ignore
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(updateCampaign.fulfilled, (state, action) => {
        console.log(action.payload);
        state.loading = false;
        state.success = true;
      });

    builder.addCase(addTagToProspect.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addTagToProspect.rejected, (state, action) => {
      // @ts-ignore
      state.error = action.payload;
      state.loading = false;
    });
    builder.addCase(addTagToProspect.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(removeTagFromProspect.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(createTag.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(updateDailyQuota.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateDailyQuota.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
    });
  },
});

export const {
  setOwner,
  setCurrentWorkSpace,
  logOut,
  setLists,
  setNewCampaignData,
  setCampaigns,
  setCampaignId,
  setListId,
  setAllProspectsList,
  setTags,
  setActionsAdded,
  removeOwnerCredit,
} = ownerSlice.actions;

export default ownerSlice.reducer;
