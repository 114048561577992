import React from "react";

import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import DefaultLayout from "./DefaultLayout";
import Home from "./pages/Home";
import Prospects from "./pages/Prospects";
import Teams from "./pages/Teams";
import Campaigns from "./pages/Campaigns/Campaigns";
import Settings from "./pages/Settings";
import "react-toastify/dist/ReactToastify.css";
import CreateCampaign from "./pages/Campaigns/CreateCampaign";
import Login from "./pages/Login";
import ImportProgressLoader from "./components/ImportProgressLoader";
import { useSelector } from "react-redux";
import { ownerSelector } from "./store/store";
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import ExpiredPlanModal from "./components/Modals/ExpiredPlanModal";

import { setIntercom } from "./utils/intercom";
import Billings from "./pages/Billings";

const DashboardLoader = () => {
  return (
    <motion.div
      initial={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ zIndex: 10000 }}
      transition={{ duration: 0.5 }}
      className="fixed inset-0 bg-white z-50 flex items-center justify-center"
    >
      <div className="flex flex-col items-center space-y-4">
        <motion.div
          animate={{
            scale: [1, 1.2, 1],
            rotate: [0, 360],
          }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            ease: "easeInOut",
          }}
          className="w-16 h-16 bg-mainColor rounded-full flex items-center justify-center"
        >
          <svg
            className="w-8 h-8 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 10V3L4 14h7v7l9-11h-7z"
            />
          </svg>
        </motion.div>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="text-gray-600 text-sm"
        >
          Loading your dashboard...
        </motion.p>
      </div>
    </motion.div>
  );
};

function App() {
  const { owner } = useSelector(ownerSelector);
  const [importSuccess, setimportSuccess] = useState(0);
  const [importScanned, setimportScanned] = useState(0);
  const [importDuplicates, setimportDuplicates] = useState(0);
  const [importFalsePositives, setimportFalsePositives] = useState(0);
  const [importPercent, setimportPercent] = useState(0);
  const [visibility, setVisibility] = useState("hidden");
  let location = useLocation();

  useEffect(() => {
    setIntercom();
  }, [location.pathname]);

  let utils = {
    setimportSuccess,
    setimportScanned,
    setimportDuplicates,
    setimportFalsePositives,
    setimportPercent,
    setVisibility,
  };

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust this time as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <AnimatePresence>
        {isLoading && <DashboardLoader />}
      </AnimatePresence>
      <ImportProgressLoader
        visibility={visibility}
        success={importSuccess}
        scanned={importScanned}
        duplicates={importDuplicates}
        falsePositives={importFalsePositives}
        percent={importPercent}
      />
      <ExpiredPlanModal show={showModal} onClose={handleCloseModal} />
      <Routes>
        {/* <Route
        path="/login"
        element={owner ? <Navigate to={"/"} /> : <Login />}
      /> */}
        {/* <Route path="/register" element={<Login />} /> */}
        <Route path="/" element={<DefaultLayout />}>
          <Route path="/" element={<Home />} />
          <Route
            path="/prospects"
            element={
              <Prospects utils={utils} setShowExpiredAlert={setShowModal} />
            }
          />
          <Route path="/teams" element={<Teams />} />
          <Route path="/billings" element={<Billings />} />

          <Route path="/settings">
            <Route index element={<Navigate to="/settings/profile" />} />
            <Route path="profile" element={<Settings />} />
            <Route path="account-activity" element={<Settings />} />

            <Route path="billing" element={<Settings />} />
            {/* <Route path="integrations" element={<Settings />} /> */}
          </Route>
          <Route path="/campaigns">
            <Route index element={<Navigate to="/campaigns/list/running" />} />
            <Route path="list" element={<Campaigns />}>
              <Route path=":id" element={<Campaigns />} />
            </Route>
            <Route path="template" element={<Campaigns />} />
            <Route
              path="create"
              element={<CreateCampaign setShowExpiredAlert={setShowModal} />}
            />
          </Route>

          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
