import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action, Campaign, Prospect } from "../../types";
import { fetchCampaignData } from "../features/campaignsFeature";
import axios from "../../utils/axios";
import { toast } from "react-toastify";

export type CampaignState = {
  campaigns: Campaign[];
  singleCampaign: {
    listId: {
      listName: string;
    };
    ownerId: string;
    campaignStatus: string;
    campaignName: string;
    prospects: Prospect[];
    actions: Action[];
    prospectsWiseCampaignActions: any[];
  };
  campaignId: string;
  newCampaignData: {
    ownerId: string;
    campaignStatus: string;
    campaignName: string;
    prospects: Prospect[];
    actions: any[];
  };
  selectedCampaign: string;

  currentStep: {
    idx: number;
    key: string;
  };
};

const initialState: CampaignState = {
  campaigns: [],
  campaignId: "",
  singleCampaign: {
    listId: {
      listName: "",
    },
    ownerId: "",
    campaignStatus: "",
    campaignName: "",
    prospects: [],
    actions: [],
    prospectsWiseCampaignActions: [],
  },
  newCampaignData: {
    ownerId: localStorage.getItem("owner")
      ? JSON.parse(localStorage.getItem("owner")!)._id
      : "",
    campaignStatus: "ACTIVE",
    campaignName: "Invitation",
    prospects: [],
    actions: [],
  },
  selectedCampaign: "",
  currentStep: {
    idx: 0,
    key: "campaignName",
  },
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState: initialState,
  reducers: {
    setCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },
    setCampaignId: (state, action) => {
      state.campaignId = action.payload;
    },
    setNewCampaignData: (state, action) => {
      state.newCampaignData = action.payload;
    },

    setSelectedCampaign: (state, action) => {
      state.selectedCampaign = action.payload;
    },
    setSingleCampaign: (state, action) => {
      state.singleCampaign = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    removeProspectFromCampaign: (state, action: PayloadAction<string>) => {
      // Add logic to remove prospect from campaign
      // set prospect with prospect._id inCampaign to false
      const {prospectId, campaignName} = JSON.parse(action.payload);
      state.singleCampaign.prospectsWiseCampaignActions = state.singleCampaign.prospectsWiseCampaignActions.map(
        (prospect: any) => {
          if(prospect._id == prospectId){
            prospect.inCampaign = false;
          }
          return prospect;
        }
      );

      state.singleCampaign.prospects = state.singleCampaign.prospects.map(
        (prospect: any) => {
          if(prospect._id == prospectId){
            prospect.inCampaign = false;
          }
          return prospect;
        }

      );
      

      // Make API call to remove prospect from campaign
      axios.post('/campaign/removeProspectFromCampaign', {
        prospectId: prospectId,
        campaignName: campaignName
      }).catch(err => {
        console.error('Error removing prospect from campaign:', err);
        toast.error('Failed to remove prospect from campaign');
      });

    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCampaignData.fulfilled, (state, action) => {
      state.singleCampaign = action.payload.campaign;
    });
  },
});

export const {
  setCampaigns,
  setCampaignId,
  setNewCampaignData,
  setSelectedCampaign,
  setSingleCampaign,
  setCurrentStep,
  removeProspectFromCampaign,
} = campaignSlice.actions;

export default campaignSlice.reducer;
